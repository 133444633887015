import React from 'react';
import { sMenuOverlay } from 'logic/responsive';
import { useEhState } from '@foursk/eh-react-hooks';
import { useHistory } from 'react-router-dom';

import SeparatorLine from 'assets/mobile-menu-separator-line.svg';
import { HeaderLink } from '../HeaderLink';
import { OverlayEgg, TopStrip } from './common';
import { navigateOrLogin } from '../../../logic/login';
import { PATHS } from '../../../logic/consts';

function MenuItem({ title, to }) {
  return (
    <h2 className="mobile-page-link">
      <HeaderLink className="mobile-page-link" to={to} alternateText={title} />
    </h2>
  );
}

export function MobileMenuOverlay() {
  const { on } = useEhState(sMenuOverlay);
  const { push } = useHistory();

  return (
    <div className={`mobile-menu-overlay${on ? ' on' : ''}`}>
      <TopStrip
        onClose={() => sMenuOverlay.fire({ on: false })}
        rightFlank={
          <div
            onClick={() => {
              // push('/hotel-guests');
              sMenuOverlay.fire({ on: false });
              // // sLoginOverlay.fire({ on: true });
              navigateOrLogin(push);
            }}
            className="shaharut-guest-round"
          >
            SHAHARUT GUEST
          </div>
        }
      />

      <OverlayEgg>
        {/* <div className="page-links"> */}
        <MenuItem title="Home" to="" />
        <MenuItem title="Library" to={PATHS.LIBRARY} />
        <MenuItem title="Stories" to={PATHS.STORIES} />
        <MenuItem title="Agenda" to={PATHS.AGENDA} />
        <img src={SeparatorLine} className="separator-line" />
        <div className="mobile-guest-link">
          <div className="shaharut-guest-big-text">
            <HeaderLink
              className="mobile-page-link"
              to={PATHS.HOTEL_GUESTS}
              alternateText="SHAHARUT GUESTS"
            />
          </div>
        </div>
        <div
        // style={ { marginBottom: '11.7%' } }
        />
      </OverlayEgg>
    </div>
    // </div>
  );
}
