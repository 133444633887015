import React from 'react';
import { sLoginOverlay } from '../../logic/responsive';
import { useEhState } from '@foursk/eh-react-hooks';
import { sLoggedIn } from '../../logic/login';
import { useHistory } from 'react-router-dom';
import { PATHS } from '../../logic/consts';

function ShaharutGuests_PROD() {
  const { loggedIn } = useEhState(sLoggedIn);
  const { push } = useHistory();
  return (
    <div
      className="shaharut-guests"
      onClick={() =>
        loggedIn
          ? push(`/${PATHS.HOTEL_GUESTS}`)
          : sLoginOverlay.fire({ on: true })
      }
    >
      SHAHARUT GUESTS
    </div>
  );
}

// function ShaharutGuests_DEV() {
//   const { loggedIn } = useEhState(sLoggedIn);
//   const { push } = useHistory();
//   const { windowHeight, windowWidth } = useEhState(sMobile);
//   return (
//     <div
//       className="shaharut-guests"
//       onClick={() =>
//         loggedIn
//           ? push(`/${PATHS.HOTEL_GUESTS}`)
//           : sLoginOverlay.fire({ on: true })
//       }
//     >
//       {/* SHAHARUT GUESTS */}
//       w: {windowWidth}
//       <br></br> h: {windowHeight}
//     </div>
//   );
// }

export const ShaharutGuests = ShaharutGuests_PROD;
