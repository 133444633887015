import React from 'react';

export function ReadButton({ ...restProps }) {
  return (
    <svg
      width="74"
      height="74"
      viewBox="0 0 74 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      { ...restProps }
      className="read-button"
    >
      <circle cx="37" cy="37" r="35.75" stroke="#FF511B" strokeWidth="2.5" />
      <path
        d="M65.6529 36.9741C65.8692 37.2947 65.8692 37.7053 65.6529 38.0259C63.4906 41.2311 51.4856 58 37 58C22.5144 58 10.5094 41.2311 8.34707 38.0259C8.13079 37.7053 8.13079 37.2947 8.34707 36.9741C10.5094 33.7689 22.5144 17 37 17C51.4856 17 63.4906 33.7689 65.6529 36.9741Z"
        fill="#FF511B"
      />
      <circle cx="37" cy="37" r="11" fill="#FFE0C1" />
    </svg>
  );
}
