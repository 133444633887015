import React from 'react';

import Cover from 'assets/agenda-top-image.jpg';

export function OurVision() {
  return (
    <div className="our-vision">
      <img src={Cover} />
      <h2>OUR VISION</h2>
      <div className="vision-divider divider"></div>
      <div className="vision-texts">
        <p>
          We wanted to create a unique space that would encapsulate the serenity
          and richness of the desert. We represented each of the countries along
          the ancient Spice Route that once ran through Shaharout, complementing
          its awe inspiring views with the music that traveled through these
          valleys for thousands of years.
        </p>
        <p>
          We searched the globe far and wide to bring our guests the most
          exquisite vinyl records from these magical countries, completing their
          stay with a hand-picked desert soundtrack, played each and every
          night, from one of the most extraordinary record collections in the
          world.
        </p>
      </div>
    </div>
  );
}
