import ApolloClient, { gql } from 'apollo-boost';
import { API_BASE_URL, AuthorizationHeader } from './api';

/** @type{ApolloClient} */
export let apollo;

export function initApolloAfterLogin() {
  apollo = new ApolloClient({
    uri: `${API_BASE_URL}/graphql`,
    headers: AuthorizationHeader,
  });
}

/**
 * Makes a unionable query
 * @param  {...string} queries
 */
export const jgql = (...queries) => {
  const qstr = `
  ${queries.join(',\n')}
  `;
  return qstr;
};

/**
 * convert unionable or normal query to gql
 * @param {string} query
 */
export const gquery = (query) => {
  if (query.trim().startsWith('{')) return gql(query);
  else {
    const wrappedQuery = `{
            ${query}
        }`;
    return gql(wrappedQuery);
  }
};

export const query = (gq) => {
  const fq = gquery(gq);
  return apollo.query({ query: fq });
};
