import React from 'react';
import { ContentRenderer } from '../../../components/stories/ContentRenderer';
import { useApollo } from '../../../components/useApollo';
import { Q_STORIES } from '../../../logic/stories';
import { sMobile } from '../../../logic/responsive';
import { useEhState } from '@foursk/eh-react-hooks';
import { arrayNameToContentType } from '../../../logic/utils';

/**
 *
 * @param {import('../../../components/stories/ContentRenderer').StoriesContentData} data
 */
function transformData(data) {
  /**@type {{[contentTag:string]:import('../../../components/stories/ContentRenderer').StoryContent[]}} */
  const dataByContentTag = {};
  for (const contentType in data) {
    for (const content of data[contentType]) {
      for (const contentTag of content.content_tags.map((c) => c.value)) {
        const contentTagData =
          dataByContentTag[contentTag] || (dataByContentTag[contentTag] = []);
        contentTagData.push({ ...content, contentType: arrayNameToContentType(contentType) });
      }
    }
  }
  return dataByContentTag;
}

function ContentTagRenderer({ name, content }) {
  return (
    <div key={name} className="mixed-content">
      <h2>{name}</h2>
      {ContentRenderer(content, false, 1118)}
    </div>
  );
}

function ContentTagsRenderer(dataByContentTag) {
  return Object.keys(dataByContentTag).map((contentTag) => (
    <ContentTagRenderer
      key={contentTag}
      name={contentTag}
      content={dataByContentTag[contentTag]}
    />
  ));
}

export function StoriesByContentTag() {
  const { isMobile } = useEhState(sMobile);
  const content = useApollo(
    ContentTagsRenderer,
    Q_STORIES(true),
    transformData
  );

  return (
    <div className="stories-by-content-tag">
      <h2>YOUR MUSICAL JOURNEY</h2>
      {!isMobile && <div className="divider"></div>}
      {content}
    </div>
  );
}
