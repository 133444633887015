import React from 'react';
import { useEhState } from '@foursk/eh-react-hooks';
import {
  resetProgress,
  playPrevious,
  playNext,
  sPlayer,
  eSeek,
  sPlayerProgress,
} from '../../logic/player';

import { PlayButton } from '../PlayButton';
import { PrevButton } from '../../assets/prevButton';
// import { Loader } from '../Loader/Loader';

export function PlayNextPrev() {
  const { piece, type, progress } = useEhState(sPlayer);
  const { loadingNext, loadingQueue } = useEhState(sPlayerProgress);

  const displayLoader = Boolean(loadingNext || loadingQueue);
  return (
    <div className="play-next-prev">
      <div className="prev-next-container">
        <PrevButton
          // alt="previous"
          // src={PrevButton}
          className="prev-next prev"
          onClick={() => {
            if (progress < 5) {
              playPrevious();
            } else {
              eSeek.fire({ seekTo: 0 });
              resetProgress();
            }
          }}
        />
      </div>
      <PlayButton piece={piece} type={type} />

      <div className="prev-next-container">
        {/* {displayLoader ? (
          <Loader
            style={{
              marginLeft: 2.5,
              marginRight: 2.5,
              width: 27,
              height: 26,
              display: 'block',
            }}
            className="prev-next"
          />
        ) : (
          <PrevButton className="prev-next next" onClick={playNext} />
        )} */}
        {displayLoader ? (
          <PrevButton
            className="prev-next next"
            onClick={() => console.log('still loading queue')}
          />
        ) : (
          <PrevButton className="prev-next next" onClick={playNext} />
        )}
        {/* <PrevButton
          className="prev-next next"
          onClick={
            displayLoader
              ? playNext
              : () => console.log('Still loading queue...')
          }
        /> */}
      </div>
    </div>
  );
}
