import { jgql, query } from './graphql';
import { EhState, EhEvent } from '@foursk/eh';
import { objectify, randomSort } from './utils';
import React, { useEffect } from 'react';

export const RECORD_FIELDS_NOTAGS = `id
    name
    artist
    duration_seconds
    audio_file {
      url
    },
`;

export const RECORD_FIELDS_NO_ALBUM = `
${RECORD_FIELDS_NOTAGS}
mood_tags {
  id
  value
}
`;

export const RECORD_FIELDS = `
${RECORD_FIELDS_NO_ALBUM}
album {
  id
  cover {
    url
  }
  artist
  description
}
`;

export const Q_RECORDS = jgql(`
  records {
    ${RECORD_FIELDS}
  }`);

export const Q_RECORD = (id) => `
{
  record(id:${id}) {
    ${RECORD_FIELDS}
  }
}
`;

export const Q_RECORD_OF_THE_DAY_ID = jgql(`recordOfTheDay {
    album {
      id
    }
  }`);

export const ALBUM_FIELDS = `cover {
  url
},
artist,
name,
description,
geo_tag {
  value
}
content_tag{
  id
}`;

export const Q_ALBUM_BY_ID = (id) => `{
    album(id:${id}){
      id
      year
      ${ALBUM_FIELDS}
    },
    records(where:{album:{id: ${id}}}) {
      ${RECORD_FIELDS_NO_ALBUM}
    }
  }`;

export const Q_LIBRARY_ALBUMS = (start = 0) => `{
    albums (start: ${start}) {
      id
      ${ALBUM_FIELDS}
      records {
        id,
        name
      }
    }
  }`;

export const Q_LIBRARY_RECORDS = (start = 0) => `{
    records (start: ${start}) {
      ${RECORD_FIELDS}
    }
  }`;

// for queries with results larger than 100 rows
export async function milk(queryWithStart, fieldName, accumulated = []) {
  const {
    data: { [fieldName]: list },
  } = await query(queryWithStart(accumulated.length));
  if (list.length === 0) {
    return accumulated;
  } else {
    accumulated = accumulated.concat(list);
    return milk(queryWithStart, fieldName, accumulated);
  }
}

export async function getLibraryAlbums() {
  return milk(Q_LIBRARY_ALBUMS, 'albums');
}

export async function getLibraryRecords() {
  return milk(Q_LIBRARY_RECORDS, 'records');
}

export const Q_ALBUM_RECORDS = (id) => `{
  album(id:${id}){
    records {
      name,
      audio_file {
        url
      }
    }
  }
}`;

export const Q_RECORD_IDS = `
{
  records {
    id
  }
}
  `;

const libraryRegionDefaultData = {
  activeRegion: 'EGYPT',
};
export const sLibraryRegion = EhState.fromInitialState(
  libraryRegionDefaultData,
  'sLibraryRegion'
);

export const eLibraryRegion = EhEvent.fromInstance(
  libraryRegionDefaultData,
  'eLibraryRegion'
);

eLibraryRegion.register((p) => console.log('RCV eLibraryRegion', p));
sLibraryRegion.register((p) => console.log('RCV sLibraryRegion', p));

export const REGION_MISC = 'MISC.';
export const REGIONS = [
  'EGYPT',
  'GREECE',
  'IRAN,IRAQ,YEMEN',
  'LEBANON',
  'TURKEY',
  'ISRAEL',
  'MAGHREB',
  REGION_MISC,
];

/**
 *
 * @param {{geo_tag: null | {value: string}}[]} albums
 */
export function arrangeAndCacheAlbums(/*{ data: { albums } }*/ albums) {
  // console.log('albums', albums);
  const regionToAlbums = objectify(REGIONS, (r) => ({ [r]: [] }));

  // console.log('regionToAlbums', regionToAlbums)
  albums.forEach((album) => {
    album.records.sort((r1, r2) => r1.name.localeCompare(r2.name));
    albumsCache[album.id] = album;
    // album.records.forEach(rec => rec.album = album);
    if (album.geo_tag && regionToAlbums[album.geo_tag.value]) {
      regionToAlbums[album.geo_tag.value].push(album);
    } else {
      album.geo_tag = { value: REGION_MISC };
      regionToAlbums[REGION_MISC].push(album);
    }
  });

  let allAlbums = [];
  // let lastIsRegion = true;
  const regionAlbums = Object.entries(regionToAlbums);
  regionAlbums.forEach(([region, albumsArray], regionIdx) => {
    albumsArray.sort(randomSort);
    albumsArray.map((a, idx) => (a.regionIdx = idx));
    albumsArray.unshift({ id: `region-${regionIdx}`, region });
    allAlbums = allAlbums.concat(albumsArray);
    // lastIsRegion = albumsArray.length === 0;
    // if (false === lastIsRegion && regionIdx < regionAlbums.length - 1)
  });
  return { albums: allAlbums, regionToAlbums };
}

export function determineIsPlaying(playing, playerPiece, buttonPiece) {
  // console.log('determineIsPlaying', { playing, playerPiece, buttonPiece });
  if (buttonPiece.records && playerPiece) {
    const recordFromAlbum = buttonPiece.records.find(
      (r) => r.id === playerPiece.id
    );
    if (recordFromAlbum) {
      return determineIsPlaying(playing, playerPiece, recordFromAlbum);
    }
  }

  const isPlaying =
    playing &&
    playerPiece &&
    buttonPiece &&
    playerPiece.id === buttonPiece.id &&
    playerPiece.type === buttonPiece.type;
  return isPlaying;
}

export function useIsPlaying(playing, playerPiece, buttonPiece) {
  const isPlaying = React.useMemo(
    () => determineIsPlaying(playing, playerPiece, buttonPiece),
    [playing, playerPiece, buttonPiece]
  );
  return isPlaying;
}

/**
 * @type {{[albumId:string]: {id: string}}}
 */
const albumsCache = {};

export async function getAlbum(id) {
  if (albumsCache[id]) return Promise.resolve(albumsCache[id]);

  const q = Q_ALBUM_BY_ID(id);
  const {
    data: { album, records },
  } = await query(q);
  if (album) {
    album.records = records;
    albumsCache[album.id] = album;
  }
  console.log('getAlbum', album);
  return album;
}

function isElementInViewport(el) {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight ||
        document.documentElement.clientHeight) /* or $(window).height() */ &&
    rect.right <=
      (window.innerWidth ||
        document.documentElement.clientWidth) /* or $(window).width() */
  );
}

export const eVisibleAlbumRegions = EhEvent.fromInstance({ regionAlbums: {} });

export function useVisibleAlbumRegions(album, albumRef, disable) {
  useEffect(() => {
    const { unregister } = eVisibleAlbumRegions.register(({ regionAlbums }) => {
      if (!albumRef || albumRef.current || disable) return;
      if (isElementInViewport(albumRef.current)) {
        const region = album.geo_tag.value;
        if (regionAlbums[region]) {
          regionAlbums[region]++;
        } else regionAlbums[region] = 1;
      }
    });

    return unregister;
  }, [album, albumRef, disable]);
}

// const get_arr = (hash, mood_tag_val) =>
//   hash[mood_tag_val] || (hash[mood_tag_val] = []);

// function gen_mood_tag_to_s3_url(albums) {
//   const albumsByMoodTag = {};
//   const promises = [];
//   let i = 0;
//   albums.forEach((a) => {
//     a.records.forEach((r) => {
//       const prom = query(Q_RECORD(r.id)).then(({ data }) => {
//         const arrs = data.record.mood_tags.map((mt) =>
//           get_arr(albumsByMoodTag, mt.value)
//         );
//         console.log(`mood tag json ${++i}/${promises.length} r=${r.id}`);
//         if (!data.record.audio_file) {
//           console.error('WHAT THE HELL', r.id);
//         } else arrs.forEach((arr) => arr.push(data.record.audio_file.url));
//       });
//       promises.push(prom);
//     });
//   });

//   Promise.all(promises).then((a) =>
//     console.log('BY MODD TAG', JSON.stringify(albumsByMoodTag))
//   );
// }
