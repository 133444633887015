import React, { useEffect, useMemo, useState } from 'react';
import { StoriesContent } from './StoriesContent';
import { JamillahCarousel } from 'components/carousel/JamillahCarousel';
import { useEhState } from '@foursk/eh-react-hooks';
import { sMobile } from '../../logic/responsive';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { CarouselItem } from '../homepage/sub/CarouselItem';

import img1 from '../../assets/the-making-of-jamilla.jpg';
import img2 from '../../assets/every-record-has-a-story.jpg';
import img3 from '../../assets/the-golden-age-of-beirut.jpg';

const images = [img1, img2, img3];

const carouselComps = [
  ({ ...carouselProps }) => (
    <CarouselItem
      title="The making of Jamillah"
      text="Watch the creation of Jamillah, from the set up of the soundsystem to the curation of the record collection. Learn how this special room came to life."
      {...carouselProps}
    >
      <Link to={''}>Watch the Video</Link>
    </CarouselItem>
  ),
  ({ rndStoryPath, ...carouselProps }) => (
    <CarouselItem
      title="Every Record Has A Story"
      text="Music is a wise narrator for history but what about the stories behind music? Uncover the incredible tales behind Jamillah's record library."
      {...carouselProps}
    >
      <Link to={rndStoryPath}>uncover a random story</Link>
    </CarouselItem>
  ),
  ({ ...carouselProps }) => (
    <CarouselItem
      title="The Golden Age of Beirut"
      text="Elias Rahbanis' Mosaic Of The Orient albums are seminal examples of the sound of Beirut in the 70's. Discover the defining sounds of East meets West."
      {...carouselProps}
    >
      <Link to="/article/14">Read the Story</Link>
    </CarouselItem>
  ),
];

const RENDER_ONLY = 'podcasts,articles';

export function Stories() {
  const [carouselImageIdx, setCarouselImageIdx] = useState(0);
  const { isMobile } = useEhState(sMobile);
  const [storiesData, setStoriesData] = useState();

  useEffect(() => {
    const gapath = window.location.pathname;
    console.log('pv ', gapath);
    ReactGA.pageview(gapath);
  }, []);

  const rndStoryPath = useMemo(() => {
    if (!storiesData) return '';
    const allStoryPaths = storiesData.transformedData[
      ['["podcasts","articles"]']
    ].map(({ contentType, id }) => `/${contentType}/${id}`);

    const rndIdx = Math.floor(Math.random() * allStoryPaths.length);
    return allStoryPaths[rndIdx];
  }, [storiesData]);

  console.log('rndStoryPath', rndStoryPath);
  return (
    <div className="stories-page">
      {isMobile && (
        <div
          style={{
            backgroundImage: `url(${
              images[Math.abs(carouselImageIdx % images.length)]
            })`,
          }}
          className="mobile-carousel-bg"
        >
          {/* <img
            key={carouselImageIdx}
            src={images[Math.abs(carouselImageIdx % images.length)]}
          /> */}
        </div>
      )}
      <div className="carousel-container">
        {/* {images[Math.abs(carouselImageIdx % images.length)]} */}
        <JamillahCarousel
          additionalClassname="stories-carousel"
          disableInfinity
          backgroundImageCallback={setCarouselImageIdx}
          slides={carouselComps.map(
            (cc) =>
              ({ ...carouselProps }) =>
                cc({ rndStoryPath, ...carouselProps })
          )}
        />
      </div>
      <StoriesContent renderOnly={RENDER_ONLY} dataCallback={setStoriesData} />
    </div>
  );
}
