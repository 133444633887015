import { jgql } from './graphql';
import { isForHotelGuestsClause } from './utils';
import { Q_CONTENT } from './content';

export const PODCAST_FIELDS = `
      id
      name
      creator
      excerpt
      duration_seconds
      cover{
        url
      }
      audio_file{
        url
      }
      content_tags{
        id
        value
      }
`;

export const SET_FIELDS = `
      id
      name
      creator
      description
      excerpt
      cover{
        url
      }
      audio_file{
        url
      }
      content_tags{
        id
        value
      }
      duration_seconds
`;

export const Q_PODCASTS = (is_for_hotel_guests = null) =>
  Q_CONTENT('podcasts', PODCAST_FIELDS, { is_for_hotel_guests });

export const Q_PODCASTS_ID = (isForHotelGuests = null) =>
  jgql(`
    podcasts ${isForHotelGuestsClause(isForHotelGuests)}{
      id
    }
`);

export const Q_PODCAST = (id) =>
  jgql(`
    podcast(id:${id}){
      ${PODCAST_FIELDS}
    }
`);

export const Q_SETS = (is_for_hotel_guests = null) =>
  Q_CONTENT('sets', SET_FIELDS, { is_for_hotel_guests });

export const Q_SETS_ID = (isForHotelGuests = null) =>
  jgql(`
    sets ${isForHotelGuestsClause(isForHotelGuests)}{
      id
    }
`);

export const Q_SET = (id) =>
  jgql(`
    set(id:${id}){
      ${SET_FIELDS}
    }
  `);
