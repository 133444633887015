import React from 'react';
import Arrow from 'assets/carousel-arrow-mobile.svg';
import { useEhState } from '@foursk/eh-react-hooks';
import { sMobile } from 'logic/responsive';

export function CarouselItem({ title, text, children, leftClick, rightClick }) {
  const { isMobile } = useEhState(sMobile);

  return (
    <div className="carousel-item">
      <div className="carousel-item-i">
        <div className="item-top">
          {isMobile && <img src={Arrow} onClick={leftClick} />}
          <h2>{title}</h2>
          {isMobile && <img src={Arrow} onClick={rightClick} />}
        </div>
        <div className="carousel-text-case">
          <p>{ text }</p>
        </div>
        { children }
      </div>
    </div>
  );
}
