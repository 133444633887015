import React from 'react';
import { useApollo } from '../../components/useApollo';
import { Q_STORIES } from '../../logic/stories';
import { shuffle, arrayNameToContentType } from '../../logic/utils';
import { ContentRenderer } from '../../components/stories/ContentRenderer';

/**
 *
 * @param {StoriesContentData} data
 */
function transformData(data, renderOnlyArray) {
  const dataId = JSON.stringify(renderOnlyArray);
  const filter =
    renderOnlyArray.length > 0
      ? (i) => renderOnlyArray.includes(i)
      : () => true;
  if (data.transformedData && data.transformedData[dataId])
    return data.transformedData[dataId];
  const transformedData = data.transformedData || {};
  const arr = [];
  Object.keys(data)
    .filter(filter)
    .forEach((contentType) =>
      arr.push(
        ...data[contentType].map((d) => ({
          contentType: arrayNameToContentType(contentType),
          ...d,
        }))
      )
    );
  const retval = shuffle(arr);
  transformedData[dataId] = retval;
  Object.assign(data, { transformedData });
  return retval;
}

const makeTransformer = (renderOnlyArray) => (data) =>
  transformData(data, renderOnlyArray);

/**
 * @param {{renderOnly:keyof StoriesContentData}} param0
 */
export function StoriesContent({ renderOnly, dataCallback }) {
  const renderOnlyArray = renderOnly
    ? renderOnly.split(',').map((c) => c.trim())
    : [];
  const content = useApollo(
    (data) => ContentRenderer(data, false, 879),
    Q_STORIES(),
    makeTransformer(renderOnlyArray),
    dataCallback
  );
  return (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      {content}
    </div>
  );
}
