import React, { useEffect, useMemo, useState } from 'react';
import { Flipper } from 'react-flip-toolkit';

import { forceCheck } from 'react-lazyload';

import { arrangeAndCacheAlbums, eLibraryRegion } from '../../logic/library';
import { useRef } from 'react';
import { LibraryCoverMode, LibraryListMode } from '../../assets/LibraryModes';
import { eAnimationDone } from '../../logic/animation';
import { Album } from './Album';
import { sMobile } from '../../logic/responsive';
import { useParams } from 'react-router-dom';
import { isSafari, isIOS, isChrome } from 'react-device-detect';
import { Loader } from '../../components/Loader/Loader';
import { AlbumsCache } from '../../logic/cache';
console.log('isSafari, isIOS, isChrome', isSafari, isIOS, isChrome);

const APPLY_SAFARI_HACK = isSafari || (isIOS && isChrome);

const albumsDefaultState = {
  albums: [],
  regionsToAlbums: {},
  realAlbums: [],
};
const makeDefaultStateForAlbum = (album) => ({
  albums: [album],
  regionsToAlbums: { [album.geo_tag.value]: [album] },
  realAlbums: [album],
});
const scrollContainerClassname = 'library-scroller';
const scrollContainerSelector = `.${scrollContainerClassname}`;
const coversDim = 295;
const coversMargin = 29;
// const coversMargin = 0;
const coversFullDim = coversDim + coversMargin;

const LIST_RENDER_COUNT = 5;
// const covers_renderCount =

/** @type {Element} */
const ELEMENT_NULL = null;

function regionNavigationLogic(album, disableInfiniteScrollRef) {
  // document.getElementById().scroll({top: 10000000})
  // scrollerRef.current.el.scroll({ top: 10000000 });
  setTimeout(() => {
    album.element.scrollIntoView(/*{ behavior: 'smooth' }*/);
    console.log('2 SCROLL', album.element);
  }, 100);

  setTimeout(() => {
    disableInfiniteScrollRef.current = false;
    console.log('3 ENABLED INFS');
  }, 500);
}

function navigateToAlbum(
  albumId,
  realAlbums,
  setRenderCursor,
  setLastIntersected,
  disableInfiniteScrollRef,
  scrollerRef
) {
  const albumIdx = realAlbums.findIndex((a) => a.id === albumId);
  const album = realAlbums[albumIdx];
  console.log('res.realAlbums', realAlbums);
  console.log('albumIdx', albumIdx);
  if (!albumIdx && albumIdx !== 0) return;
  // scrollerRef.current.el.scrollTop =
  //   2 * lastIntersectedRef.current.getBoundingClientRect().height;
  setRenderCursor(Math.max(0, albumIdx - 2));
  setLastIntersected({ element: ELEMENT_NULL, renderIdx: albumIdx });
  disableInfiniteScrollRef.current = true;
  console.log('1 DISABLED INFS');
  if (!album.element) {
    album.elementCallback = () =>
      regionNavigationLogic(album, disableInfiniteScrollRef, scrollerRef);
  } else {
    // alert('existing');
    setTimeout(() => {
      regionNavigationLogic(album, disableInfiniteScrollRef, scrollerRef);
    }, 10);
  }
}

export function Albums({ playerMode, album, listMode, setListMode }) {
  const [albumsState, setAlbums] = useState(
    album ? makeDefaultStateForAlbum(album) : albumsDefaultState
  );

  const [lastIntersected, setLastIntersected] = useState({
    renderIdx: 0,
    element: ELEMENT_NULL,
  });
  const lastIntersectedRef = useRef();

  const [renderCursor, setRenderCursor] = useState(0);

  const { id } = useParams();

  const [coversInRow, setCoversInRow] = useState(4);

  const disableInfiniteScrollRef = useRef(false);

  const scrollerRef = useRef();
  // const albumsRef = useRef();

  useEffect(() => {
    if (playerMode) return;
    let unregister1;

    const updateCoversInRow = () => {
      const strPadding = window
        .getComputedStyle(scrollerRef.current.el, null)
        .getPropertyValue('padding-left');
      const padding = parseInt(strPadding.replace('px', ''));
      setCoversInRow(
        Math.floor(
          (scrollerRef.current.el.getBoundingClientRect().width - padding) /
            coversFullDim
        )
      );
    };

    let { unregister: unregister2 } = sMobile.register(updateCoversInRow);
    updateCoversInRow();

    AlbumsCache.getCache()
      .then(arrangeAndCacheAlbums)
      .then((res) => {
        const { albums, regionToAlbums } = res;

        // res.albumsForRender = albums.slice(0, LIST_RENDER_COUNT);
        // res.renderCursor = 0;
        res.realAlbums = albums.filter((a) => !!a.artist);
        setAlbums(res);
        const { unregister } = eLibraryRegion.register(({ activeRegion }) => {
          console.log('activeRegion', { activeRegion, regionToAlbums });
          const regionalAlbums = regionToAlbums[activeRegion];
          if (!regionalAlbums || regionalAlbums.length < 2) return; // < 2 and not === 0 because for each region there is a default region marker in the list
          console.log('regionalAlbums', regionalAlbums);

          const album = regionalAlbums[1];
          console.log('album', album);
          if (!album) return;
          navigateToAlbum(
            album.id,
            res.realAlbums,
            setRenderCursor,
            setLastIntersected,
            disableInfiniteScrollRef,
            scrollerRef
          );
          // setTimeout(() => {
          //   album.element.scrollIntoView({ behavior: 'smooth' });
          //   console.log('2 SCROLL', album.element);
          //   setTimeout(() => {
          //     disableInfiniteScrollRef.current = false;
          //     console.log('3 ENABLED INFS');
          //   }, 500);
          // }, 0);
        });
        unregister1 = unregister;
        return albums;
      });
    // .then((albums) => {
    //   sLibraryRegion.fire({ activeRegion: albums[1]?.geo_tag.value });
    //   if (id) {
    //     const album = albums.find((a) => a.id === id);
    //     if (album && album.element) {
    //       album.element.scrollIntoView();
    //     }
    //   }
    // });

    return () => {
      if (unregister1) unregister1();
      if (unregister2) unregister2();
    };
  }, [setRenderCursor, playerMode]);

  useEffect(() => {
    lastIntersectedRef.current = lastIntersected.element;
  }, [lastIntersected]);

  // change rendered albums
  useEffect(() => {
    if (true === disableInfiniteScrollRef.current) return;
    let newRenderCursor = renderCursor;
    if (lastIntersected.renderIdx - 1 <= renderCursor) {
      //load above
      newRenderCursor = Math.max(
        0,
        Math.min(lastIntersected.renderIdx, renderCursor) - 2
      );
    } else if (
      lastIntersected.renderIdx - renderCursor >=
      LIST_RENDER_COUNT - 1
    ) {
      newRenderCursor = renderCursor + 2;
    } else return;

    // setRenderCursor(Math.min(2, newRenderCursor));
    console.log('SET NEW RENDER CURSOR');
    setRenderCursor(newRenderCursor);
  }, [lastIntersected, renderCursor]);

  // render album from navigation parameter
  useEffect(() => {
    // if (id) {
    //   const album = albumsState.albums.find((a) => a.id === id);
    //   if (album && album.element) {
    //     album.element.scrollIntoView();
    //   }
    // }
    if (id && albumsState.realAlbums.length > 0)
      navigateToAlbum(
        id,
        albumsState.realAlbums,
        setRenderCursor,
        setLastIntersected,
        disableInfiniteScrollRef,
        scrollerRef
      );
  }, [
    id,
    albumsState.realAlbums,
    setRenderCursor,
    setLastIntersected,
    disableInfiniteScrollRef,
    scrollerRef,
  ]);

  const { albums, realAlbums } = albumsState;

  const [albumsForRender, padders] = useMemo(() => {
    if (listMode) {
      const forRender = realAlbums.slice(
        renderCursor,
        Math.min(renderCursor + LIST_RENDER_COUNT, realAlbums.length)
      );

      if (APPLY_SAFARI_HACK && lastIntersectedRef.current) {
        const height =
          lastIntersectedRef.current.getBoundingClientRect().height;
        const padders = [];
        for (let i = 0; i < renderCursor; i++) {
          padders.push(
            <div key={`lp-${i}`} style={{ height, width: '100%' }} />
          );
        }
        // console.log('SAFARI HEIGHT HACK', { padders: padders.length, height });
        return [forRender, padders];
      } else return [forRender, []];
    } else {
      return [albums, []];
    }
  }, [albums, realAlbums, listMode, renderCursor]);

  // const navigateToAlbumById = (id) =>
  //   navigateToAlbum(
  //     id,
  //     realAlbums,
  //     setRenderCursor,
  //     setLastIntersected,
  //     disableInfiniteScrollRef,
  //     scrollerRef
  //   );

  const selectAlbumFromCovers = (album) => {
    eAnimationDone.registerOnce(() => {
      console.log('COVER->LIST navigate to album');
      navigateToAlbum(
        album.id,
        realAlbums,
        setRenderCursor,
        setLastIntersected,
        disableInfiniteScrollRef,
        scrollerRef
      );
    });

    console.log('COVER->LIST set list = true');
    disableInfiniteScrollRef.current = true;
    setListMode(true);

    return;
  };

  // scroll to last rendered album after rendered albums change
  // useEffect(() => {
  //   if (lastIntersectedRef.current) {
  //     console.log('Scroll fix: SCROLLING!');
  //     lastIntersectedRef.current.scrollIntoView({ behavior: 'auto' });
  //   }
  // }, [albumsForRender]);

  // console.log('albumsForRender', {
  //   albumsForRender,
  //   renderCursor,
  //   lastIntersected: lastIntersected.renderIdx,
  // });

  // if (scrollerRef.current) {
  //   const { scrollHeight, scrollTop } = scrollerRef.current.el;
  //   console.log('Scroll', { scrollHeight, scrollTop });
  // }

  return (
    <Flipper
      id="library-flipper"
      flipKey={listMode}
      className={scrollContainerClassname}
      ref={scrollerRef}
      onComplete={() => eAnimationDone.fire({ name: 'flipper' })}
    >
      {/* <div
        className={`${scrollContainerClassname} ${
          listMode ? 'list' : 'covers'
        }`}
      > */}
      <div className="library-header">
        <span>
          <LibraryCoverMode
            onClick={() => {
              eAnimationDone.registerOnce(forceCheck);
              setListMode(false);
            }}
          />

          <LibraryListMode onClick={() => setListMode(true)} />
        </span>
        <span className="center">
          <div className="title">LIBRARY</div>
          <div className="divider"></div>
        </span>
        <span></span>
      </div>

      <div
        className="albums"
        // ref={albumsRef}
        style={{
          ['--covers-in-row']: coversInRow,
          ['--covers-dim']: `${coversDim}px`,
          ['--covers-margin']: `${coversMargin}px`,
        }}
      >
        {albumsForRender.length === 0 && <Loader />}
        {padders}
        {albumsForRender.map((album, idx) => (
          <Album
            album={album}
            key={album.id}
            scrollContainerSelector={scrollContainerSelector}
            setListMode={setListMode}
            listMode={listMode}
            renderIdx={renderCursor + idx}
            setLastIntersected={setLastIntersected}
            displayAlbum={selectAlbumFromCovers}
            coversInRow={coversInRow}
            // scrollOnExplore
          />
        ))}
      </div>
      {/* </div> */}
    </Flipper>
  );
}
