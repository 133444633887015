import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Homepage } from './pages/homepage/Homepage';
import { Agenda } from './pages/agenda/Agenda';
import { Library } from './pages/library/Library';
import { Stories } from './pages/stories/Stories';
import { HotelGuests } from './pages/guests/HotelGuests';
import { Player } from './components/player/Player';
import { Header } from 'components/header/Header';
import {
  MobileMenuOverlay,
  GuestLoginOverlay,
} from 'components/header/overlay';

import { useEhState } from '@foursk/eh-react-hooks';
import { StoryFeed } from './pages/content/StoryFeed';
import { AppContent } from './components/AppContent';
import { sMobile, sScroll } from './logic/responsive';
import { Loader } from './components/Loader/Loader';
import { login } from './logic/api';
import { initApolloAfterLogin } from './logic/graphql';

import { sPlayer, sPlayerPopup } from './logic/player';
import { OpenPlayerDesktop, OpenPlayerMobile } from './assets/openPlayer';
import { PlayerPopup } from './components/player/PlayerPopup';
import { GAID, PATHS } from './logic/consts';
import { isSafari } from 'react-device-detect';
import { isIPAllowed } from './logic/ip-whitelist';
import { sLoggedIn } from './logic/login';
import ReactGA from 'react-ga';

ReactGA.initialize(GAID);

export default function App() {
  const [loggedIn, setLoggedIn] = useState(0);
  const { scroll, scrollAnim } = useEhState(sScroll);
  const {
    isMobile: isMobileWidth,
    windowWidth,
    windowHeight,
    portrait,
  } = useEhState(sMobile);

  const { on: popupOpen } = useEhState(sPlayerPopup);
  const { piece } = useEhState(sPlayer);
  const { ipAllowed } = useEhState(sLoggedIn);

  useEffect(() => {
    try {
      login().then(() => {
        initApolloAfterLogin();
        isIPAllowed();
        setLoggedIn(1);
      });
    } catch (e) {
      setLoggedIn(-1);
    }
  }, []);

  if (0 === loggedIn) {
    return <Loader />;
  } else if (-1 === loggedIn) {
    return (
      <h2 style={{ margin: 'auto' }}>
        Oops... Seems like there is a conncetivity issue
      </h2>
    );
  } else {
    const OpenPlayer = isMobileWidth ? OpenPlayerMobile : OpenPlayerDesktop;
    const OpenPlayerComp = (
      <OpenPlayer
        onClick={() => {
          if (piece && piece.id) sPlayerPopup.fire({ on: !popupOpen });
        }}
        className={`open-player${popupOpen ? ' open' : ''}`}
      />
    );
    return (
      <BrowserRouter>
        <div
          className={`app${scrollAnim ? ' scrollanim' : ''} ${
            isMobileWidth ? 'mobile' : 'desktop'
          } ${portrait ? 'portrait' : 'landscape'}
          ${isSafari ? ' safari' : ''}`}
          style={{
            '--cscroll': scroll + 'px',
            '--cscrollp': Math.min(scroll / 170, 1),
            // '--cscrollp': Math.min(scroll / 570, 1),
            '--vw100': `${windowWidth}px`,
            '--vh100': `${windowHeight}px`,
            '--scrollbar-thumb': '#ff511b',
          }}
        >
          <Header />
          {ipAllowed && (
            <>
              <MobileMenuOverlay />
              <GuestLoginOverlay />
            </>
          )}

          <AppContent>
            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
            {ipAllowed && (
              <Switch>
                <Route path={'/' + PATHS.AGENDA}>
                  <Agenda />
                </Route>

                <Route path={`/${PATHS.LIBRARY}/:id?`}>
                  <Library />
                </Route>
                <Route path={'/' + PATHS.STORIES}>
                  <Stories />
                </Route>
                <Route path={'/' + PATHS.HOTEL_GUESTS}>
                  <HotelGuests />
                </Route>
                <Route path="/article/:id">
                  <StoryFeed firstContentType="article" />
                </Route>
                <Route path="/set/:id">
                  <StoryFeed firstContentType="set" />
                </Route>
                <Route path="/podcast/:id">
                  <StoryFeed firstContentType="podcast" />
                </Route>
                <Route path="/">
                  <Homepage />
                </Route>
              </Switch>
            )}
            {ipAllowed === false && <Agenda />}
          </AppContent>
          {ipAllowed && (
            <>
              <Player OpenPlayerComp={OpenPlayerComp} />
              <PlayerPopup
                open={popupOpen}
                openPlayerButton={OpenPlayerComp}
                piece={piece}
              />
            </>
          )}
          {/* <JaButton text="Reset Player" onClick={clearState} /> */}
        </div>
        {/* <SafariOverlayHack /> */}
      </BrowserRouter>
    );
  }
}
