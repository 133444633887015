import { EhEvent } from '@foursk/eh';
import { Flipper } from 'react-flip-toolkit';

export const eAnimationDone = EhEvent.fromInstance({ name: '' });

export function hookFlipToolkit() {
  Flipper.prototype.originalComponentDidUpdate =
    Flipper.prototype.componentDidUpdate;

  Flipper.prototype.componentDidUpdate = function componentDidUpdate(
    prevProps,
    _prevState,
    cachedData
  ) {
    if (this.props.flipKey !== prevProps.flipKey && this.el) {
      setTimeout(
        () =>
          this.originalComponentDidUpdate(prevProps, _prevState, cachedData),
        500
      );
    } else this.originalComponentDidUpdate(prevProps, _prevState, cachedData);
  };
}
