import { useQuery } from '@apollo/react-hooks';
import React, { useEffect } from 'react';
import { Q_EVENT_NEXT_MONTH } from '../../logic/events';
import { apollo, gquery } from '../../logic/graphql';
import { MonthlyEvents } from './sub/MonthlyEvents';
import { OurVision } from './sub/OurVision';
import { TheSoundsystem } from './sub/TheSoundsystem';
import ReactGA from 'react-ga';

export function Agenda() {
  const { data } = useQuery(gquery(Q_EVENT_NEXT_MONTH()), { client: apollo });

  useEffect(() => {
    const gapath = window.location.pathname;
    console.log('pv ', gapath);
    ReactGA.pageview(gapath);
  }, []);

  return (
    <div className="agenda">
      <OurVision />
      <TheSoundsystem />
      {data && data.events && data.events.length > 0 && <MonthlyEvents />}
    </div>
  );
}
