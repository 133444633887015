import React from 'react';

export function LibraryCoverMode({...props}) {
  return (
    <svg
      className="mode-cover"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="5" height="5" />
      <rect x="8" width="5" height="5" />
      <rect x="16" width="5" height="5" />
      <rect y="8" width="5" height="5" />
      <rect x="8" y="8" width="5" height="5" />
      <rect x="16" y="8" width="5" height="5" />
      <rect y="16" width="5" height="5" />
      <rect x="8" y="16" width="5" height="5" />
      <rect x="16" y="16" width="5" height="5" />
    </svg>
  );
}

export function LibraryListMode({...props}) {
  return (
    <svg
      className="mode-list"
      width="25"
      height="21"
      viewBox="0 0 25 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="25" height="3" />
      <rect y="9" width="25" height="3" />
      <rect y="18" width="25" height="3" />
    </svg>
  );
}
