import { jgql } from './graphql';

/**
 * 
 * @param {Date} date 
 */
const formatDate = date => `${ date.toISOString().substring(0, 10) }`;

export const Q_EVENTS_TODAY = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const q = `
  events(sort: "datetime", where: { is_for_hotel_guests: "true", datetime_gte: "${formatDate(today) }", datetime_lt: "${ formatDate(tomorrow) }" }) {
    id
    datetime
    name
    description
    location
  }
  `;
  return jgql(q);
};

export const Q_EVENT_NEXT_MONTH = () => {
  const today = new Date();
  const nextMonth = new Date(today);
  nextMonth.setMonth(today.getMonth() + 1);
  const query = jgql(`
    events (sort: "datetime", where: {datetime_gte: "${formatDate(today) }", datetime_lt: "${ formatDate(nextMonth) }", is_for_hotel_guests: false }){
      id
      name
      datetime
      location
      description
    }
`);
  return query;
};