import { EhState } from '@foursk/eh';
import debounce from 'debounce';

export const MOBILE_WIDTH = 1024;

export let windowWidthForRender = window.innerWidth;
export let windowHeightForRender = window.innerHeight;

const sMobileMakeState = (
  windowWidth = (windowWidthForRender = window.innerWidth),
  windowHeight = (windowHeightForRender = window.innerHeight)
) => ({
  isMobile: windowWidth <= MOBILE_WIDTH,
  windowWidth,
  windowHeight,
  landscape: windowWidth > windowHeight,
  portrait: windowHeight >= windowWidth,
});

export const sMobile = EhState.fromInitialState(sMobileMakeState(), 'sMobile');
export const sScroll = EhState.fromInitialState({
  scroll: 0,
  el: document.firstElementChild,
  scrollAnim: false,
}, 'sScroll');
export const sMenuOverlay = EhState.fromInitialState({ on: false }, 'eMenuOverlay');
export const sLoginOverlay = EhState.fromInitialState({
  on: false,
  error: false,
}, 'sLoginOverlay');

window.addEventListener(
  'resize',
  debounce(() => sMobile.fire(sMobileMakeState()), 200)
);

sMobile.register(({ isMobile }) => {
  if (false === isMobile) {
    sMenuOverlay.fire({ on: false });
  }
});
