import { query } from './graphql';
import { sLoggedIn } from './login';
import publicIp from 'public-ip';

/**
 *
 * @returns {Promise<string[]>}
 */
async function getAllowedIPS() {
  const { data } = await query(`{
          allowedIps{
    ip,
    enabled
  }
    }`);

  return data.allowedIps.filter(({ enabled }) => enabled).map(({ ip }) => ip);
}

export async function isIPAllowed() {
  // publicIp.v4().then(setIsAllowedIP);
  const [allowedIPs, myIP] = await Promise.all([
    getAllowedIPS(),
    publicIp.v4(),
  ]);

  const ipAllowed = allowedIPs.includes(myIP);
  sLoggedIn.fire({ ipAllowed });
  return ipAllowed;
}
