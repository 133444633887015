import React from 'react';
import { useEhState } from '@foursk/eh-react-hooks';
import { sPlayer } from '../../logic/player';

import 'rc-slider/assets/index.css';
import 'rc-progress/assets/index.css';

import { Volume } from './Volume';
import { PlayerProgress } from './PlayerProgress';
import { PlayNextPrev } from './PlayNextPrev';

function PlayerTexts({ artist, creator, name }) {
  return (
    <div className="player-texts">
      <div className="artist">{artist || creator}</div>
      <div className="name">{name}</div>
    </div>
  );
}

export function Player({ OpenPlayerComp }) {
  const { piece } = useEhState(sPlayer);

  const url = piece && piece.audio_file ? piece.audio_file.url : '';

  return (
    <div className="player-container">
      <PlayerProgress />
      <div className="player">
        <PlayerTexts {...piece} />
        <PlayNextPrev />
        <div className="player-right">
          {url !== '' && OpenPlayerComp}
          <Volume />
        </div>
      </div>
    </div>
  );
}
