import { EhState } from '@foursk/eh';
import { PATHS } from './consts';
import { sLoginOverlay } from './responsive';

const LOCAL_STORAGE_KEY = 'kupicrypt';

export const sLoggedIn = EhState.fromInitialState({
  loggedIn: false,
  ipAllowed: false,
});

const localStorageState = localStorage.getItem(LOCAL_STORAGE_KEY);
console.log('localStorageState', localStorageState);
if (localStorageState) {
  sLoggedIn.fire(JSON.parse(localStorageState));
}

sLoggedIn.register((state) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
});

export function navigateOrLogin(push) {
  if (sLoggedIn.state.loggedIn) {
    push(`/${PATHS.HOTEL_GUESTS}`);
  } else {
    sLoginOverlay.fire({ on: true });
  }
}
