import React from 'react';

function Button({ miniText, mainText, buttonType, onClick }) {
  return (
    <div className={`jamilla-button ${buttonType}`} onClick={onClick}>
      <div className="mini-text">{ miniText }</div>
      <div className="main-text">{mainText}</div>
    </div>
  );
}

export function ButtonBracket({ miniText, mainText, onClick }) {
  return (
    <Button
      miniText={miniText}
      mainText={mainText}
      buttonType="bracket"
      onClick={onClick}
    />
  );
}

export function ButtonRound({ miniText, mainText, onClick }) {
  return (
    <Button
      miniText={miniText}
      mainText={mainText}
      buttonType="round"
      onClick={onClick}
    />
  );
}
