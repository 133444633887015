import React from 'react';
import { Events } from 'components/events/Events';

export function MonthlyEvents() {
  return (
    <div className="monthly-events">
      <h2>Program</h2>
      <div className="divider"></div>
      <Events />
    </div>
  );
}
