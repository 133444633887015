import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { sScroll } from '../logic/responsive';
import { eCustomScroll } from '../logic/custom-scroll';

export function AppContent({ children }) {
  const refContent = useRef();
  const history = useHistory();
  useEffect(() => {
    history.listen(() => {
      refContent.current.scrollTo({ top: 0, left: 0 });
    });

    eCustomScroll.register(({ scrollDelta, path }) => {
      if (path && path.includes(refContent.current)) {
        return;
      }
      refContent.current.scrollBy(0, scrollDelta);
    });
  }, []);

  return (
    <div
      className="app-content"
      ref={refContent}
      onScroll={() => {
        const scroll = refContent.current.scrollTop;
        const event = {
          scroll,
          el: refContent.current,
          scrollAnim: scroll >= 10,
        };
        sScroll.fire(event);
      }}
    >
      {children}
    </div>
  );
}
