import React from 'react';
import { sPlayer, play, pause } from '../logic/player';
import { useEhState } from '@foursk/eh-react-hooks';
import { useIsPlaying } from '../logic/library';

/**
 * 
 * @param {{piece:{}, type: import('../logic/player').AudioType, style: "normal" | "floating" | "story"}} param0 
 */
export function PlayButton({ piece, type, style }) {
  // console.log('PLAY BUTTON RENDER');
  const { playing, piece: playerPiece } = useEhState(sPlayer);
  const localIsPlaying = useIsPlaying(playing, playerPiece, piece);
  const [, onClick] = localIsPlaying ? ['Pause', () => pause()] : ['Play', () => play(piece, type)];
  return (
    <div onClick={ e => {
      e.stopPropagation();
      onClick();
    } } className={ `play-button ${ localIsPlaying ? 'playing' : 'paused' } ${ style }` }>
      <div></div>
    </div>
  );
}
