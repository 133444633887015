import { Q_RECORDS_QUEUE, Q_SETS_QUEUE, Q_PODCASTS_QUEUE } from '../queue';
import { Q_RECORD } from '../library';
import { Q_SET, Q_PODCAST } from '../journeys';

export const hasTags = (piece, tag, tagId) =>
  piece[tag] &&
  undefined !== piece[tag].find((pieceTag) => pieceTag.id === tagId);

export const existingQueueFilter = (currentCursor, piece) => (v, idx) =>
  idx <= currentCursor &&
  false === (v.id === piece.id && v.contentType === piece.contentType);

export const queriesForAudioType = {
  record: {
    queue: Q_RECORDS_QUEUE,
    piece: Q_RECORD,
    tag: 'mood_tags',
  },
  set: {
    queue: Q_SETS_QUEUE,
    piece: Q_SET,
    tag: 'content_tags',
  },
  podcast: {
    queue: Q_PODCASTS_QUEUE,
    piece: Q_PODCAST,
    tag: 'content_tags',
  },
};

/**
 *
 * @param {AudioType} type
 * @param {number} id
 */
export const Q_PIECE = (type, id) => {
  return queriesForAudioType[type].piece(id);
};
