import uniqBy from 'lodash/uniqBy';
import { queriesForAudioType, existingQueueFilter, hasTags } from './common';
import { sPlayer, sPlayerProgress } from './state';
import { query } from '../graphql';
import { randomSort } from '../utils';
import { RecordsCache } from '../cache';

const queueUniq = (queue) =>
  uniqBy(queue, ({ id, contentType }) => `${id}-${contentType}`);

const recordsByMood = {};
let albumsCached = false;

async function cacheByMood() {
  const records = await RecordsCache.getCache();
  records.forEach((r) => {
    const list =
      recordsByMood[r.mood_tags[0].value] ||
      (recordsByMood[r.mood_tags[0].value] = []);
    list.push(r);
  });

  albumsCached = true;
}

async function buildQueueGetRecords(piece, type, excludeId) {
  const { queue: queueQuery, tag } = queriesForAudioType[type];
  const { queue: existingQueue, cursor: currentCursor } = sPlayer.state;
  if (!piece[tag] || !piece[tag].length === 0) {
    console.warn('No Mood');
    return;
  }
  const tagId = piece[tag][0].id;
  let list = null;
  if (type === 'record') {
    if (false === albumsCached) {
      await cacheByMood();
    }

    list = recordsByMood[piece.mood_tags[0].value].filter(
      (r) => r.id !== excludeId
    );
  } else {
    const { data } = await query(queueQuery(excludeId));
    list = data;
  }
  return [list, tagId, existingQueue, currentCursor, tag];
}

/**
 * @param {number} tagId
 * @param {AudioType} type
 * @param {number} excludeId
 */
export async function buildQueue(piece, type, excludeId) {
  await sPlayerProgress.fire({ loadingQueue: true });
  const now = new Date();
  const [data, tagId, existingQueue, currentCursor, tag] =
    await buildQueueGetRecords(piece, type, excludeId);
  const delta = new Date() - now;
  console.count('delta');
  console.log(delta);
  const fieldName = type + 's';
  const newPieces = (data[fieldName] || data)
    .map((i) => {
      i.contentType = type;
      return i;
    })
    .filter((p) => p[tag].find((t) => t.id === tagId)) // filter out pieces with irrelevant tags
    .sort(randomSort); // sort randomly
  newPieces.unshift(piece);

  const playedPieces = existingQueue.filter(
    existingQueueFilter(currentCursor, piece)
  );

  const nonUniqQueue = playedPieces
    .slice(playedPieces.length - 5)
    .concat(newPieces);
  const queue = queueUniq(nonUniqQueue);
  await sPlayer.fire({
    queue,
    cursor: playedPieces.length,
    tagType: tag,
    tagId,
  });
  console.log('New queue', { queue });
  await sPlayerProgress.fire({ loadingQueue: false });
}

/**
 * This function reshuffles queue with the selected piece first, happens when a user explicitly plays a piece with and identical tag
 * @param {*} piece
 */
export async function reshuffleQueue(piece) {
  const {
    queue: existingQueue,
    cursor: currentCursor,
    tagType,
    tagId,
  } = sPlayer.state;
  const playedPieces = existingQueue.filter(
    existingQueueFilter(currentCursor, piece)
  );
  const queueRnd = existingQueue
    .filter((piece) => hasTags(piece, tagType, tagId))
    .sort(randomSort);
  queueRnd.unshift(piece);
  const queue = queueUniq(playedPieces.concat(queueRnd));
  await sPlayer.fire({ queue, cursor: playedPieces.length });
}
