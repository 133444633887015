import { useHistory } from 'react-router-dom';
import { EhState } from '@foursk/eh';
import { useEhState } from '@foursk/eh-react-hooks';
import { useEffect } from 'react';

export const sPathname = EhState.fromInitialState({
  pathname: window.location.pathname,
}, 'sPathname');

function altPush(pathname) {
  sPathname.fire({ pathname });
  return this.pushOrig(pathname);
}

/**
 * UseHistory work around, updates path according to pushes
 */
export function useHistoryWA() {
  const history = useHistory();
  const { pathname } = useEhState(sPathname);

  useEffect(() => {
    sPathname.fire({ pathname: window.location.pathname });
  }, []);

  if (!history.pushOrig) {
    history.pushOrig = history.push;
    history.push = altPush.bind(history);
  }

  history.pathname = pathname;

  return history;
}
