import React from 'react';
import { PlayButton } from '../PlayButton';

export function PlayTitle({ piece, artist, name }) {
  return (
    <div className="play-title">
      <PlayButton piece={piece} style="floating" type="record" />
      <div className="artist-name">
        <span>{artist}</span>
        <span>{name}</span>
      </div>
    </div>
  );
}
