import React from 'react';
import { GuestContent } from './sub/GuestContent';
import { useEhState } from '@foursk/eh-react-hooks';
import { sLoggedIn } from '../../logic/login';
import { sLoginOverlay } from '../../logic/responsive';
import { useEffect } from 'react';
import ReactGA from 'react-ga';

export function HotelGuests() {
  const { loggedIn } = useEhState(sLoggedIn);
  const { on } = useEhState(sLoginOverlay);

  useEffect(() => {
    const gapath = window.location.pathname;
    console.log('pv ', gapath);
    ReactGA.pageview(gapath);
  }, []);

  useEffect(() => {
    if (!on && !loggedIn) sLoginOverlay.fire({ on: true });
  }, [on, loggedIn]);

  if (loggedIn) {
    return <GuestContent />;
  } else {
    return (
      <h3 className="hotel-guests-please-login">
        Please log in to access your personal zone
      </h3>
    );
  }
}
