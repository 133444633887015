import React from 'react';
import { useEhState } from '@foursk/eh-react-hooks';
import { sMobile, sMenuOverlay } from '../../logic/responsive';
import { useHistoryWA } from '../../logic/history';

/**
 *
 * @param {{to:string, text:string}} param0
 */
function HeaderLinkMobile({ to, alternateText }) {
  const { push } = useHistoryWA();
  return (
    <a
      onClick={() => {
        sMenuOverlay.fire({ on: false });
        push(`/${to}`);
      }}
      style={{ textDecoration: 'inherit', color: 'inherit' }}
      // to={to}
    >
      {alternateText === undefined ? to + ' ' : alternateText + ' '}
    </a>
  );
}

/**
 *
 * @param {{to:string, text:string}} param0
 */
function HeaderLinkDesktop({ to }) {
  const { push } = useHistoryWA();
  return (
    <a
      onClick={() => {
        push(`/${to}`);
      }}
    >
      {to + ' '}
    </a>
  );
}

export function HeaderLink({ ...props }) {
  const { isMobile } = useEhState(sMobile);
  const compFunc = isMobile ? HeaderLinkMobile : HeaderLinkDesktop;
  return compFunc({ ...props });
}
