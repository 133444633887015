import React from 'react';
import { useEhState } from '@foursk/eh-react-hooks';
import { sPlayerProgress } from '../../logic/player';

export function PlayerProgress() {
  const { percentageProgress } = useEhState(sPlayerProgress);

  return (
    <div className="player-progress">
      <div
        style={{
          width: `${percentageProgress}%`,
          /*transition: percentage === 0 ? 0 : ''*/ transition: 'unset',
        }}
      ></div>
    </div>
  );
}
