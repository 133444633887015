import { RECORD_FIELDS } from './library';
import { PODCAST_FIELDS, SET_FIELDS } from './journeys';
const excludeIdClause = excludeId => `(where:{id_ne: ${excludeId}})`;

export const Q_RECORDS_QUEUE = (excludeId) => `{
    records ${excludeIdClause(excludeId)} {
        ${RECORD_FIELDS}
    }
}`;

export const Q_PODCASTS_QUEUE = (excludeId) => `{
    podcasts ${excludeIdClause(excludeId)} {
        ${PODCAST_FIELDS}
    }
}`;

export const Q_SETS_QUEUE = (excludeId) => `{
    sets ${excludeIdClause(excludeId)} {
        ${SET_FIELDS}
    }
}`;
