import React from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from '../../../logic/consts';
import { CarouselItem } from './CarouselItem';

export function Unlock({ ...carouselProps }) {
  return (
    <CarouselItem
      title="Explore the stories behind the music."
      // text="discover tailor made content perfect for your stay in Shahrut. Music to delight your afternoon, essential read for you morning coffee and the ultimate soundtrack for your spa session."
      text="Relive the golden era of Beirut through the albums that shaped it, discover the legendary voices of Egypt, or dive into the illustrious world of Anatolian rock."
      {...carouselProps}
    >
      <Link to={PATHS.STORIES}>Go to our stories</Link>
    </CarouselItem>
  );
}
