import { EhState, EhEvent } from '@foursk/eh';

const LOCAL_STORAGE_KEY = 'player-state';

/**@typedef {'record' | 'set' | 'podcast'}  AudioType*/

const defaultPlayerState = {
  playing: false,
  piece: {
    url: '',
    name: '',
    cover: '',
  },
  /** @type {AudioType} */
  type: '',
  volume: 0.5,
  queue: [],
  cursor: 0,
  tagType: '',
  tagId: '',
  audio: new Audio(),
};

const stateStrFromStorage = localStorage.getItem(LOCAL_STORAGE_KEY);
let initialPlayerState = defaultPlayerState;
initialPlayerState = Object.assign(
  {},
  defaultPlayerState,
  stateStrFromStorage ? JSON.parse(stateStrFromStorage) : {}
);

export const sPlayer = EhState.fromInitialState(initialPlayerState, 'sPlayer');

export const eSeek = EhEvent.fromInstance({ seekTo: 0 }, 'eSeek');

export const sPlayerProgress = EhState.fromInitialState({
  progress: 0,
  percentageProgress: 0,
  duration: 0,
  loadingNext: false,
  loadingQueue: false,
});

export const sPlayerPopup = EhState.fromInitialState(
  { on: false },
  'sPlayerPopup'
);

export const progressSettings = {
  progressStep: 0.5 / 100,
  progressInterval: 500,
};

function decirculizePieces(originalPiece) {
  const { album, ...piece } = originalPiece;
  if (album) {
    // eslint-disable-next-line
    const { records, element, intersections, ...albumWithoutCirc } = album;
    piece.album = albumWithoutCirc;
  }

  return piece;
}

sPlayer.register((state) => {
  const {
    piece: originalPiece,
    queue: originalQueue,
    ...stateWithoutPiece
  } = state;
  const piece = decirculizePieces(originalPiece);
  const queue = originalQueue.map(decirculizePieces);

  const stateForSaving = Object.assign(
    {},
    stateWithoutPiece,
    { piece, queue },
    { playing: false }
  );
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(stateForSaving));

  // console.log('sPlayer', { cursor: state.cursor, queue: state.queue });
  // console.log('sPlayer', sPlayer.stateData);
});

export function resetProgress() {
  sPlayer.state.audio.currentTime = 0;
}

export async function clearState() {
  return sPlayer.fire({ ...defaultPlayerState });
}

export function seek(seekTo) {
  sPlayer.state.audio.currentTime = seekTo;
  return eSeek.fire({ seekTo });
}
