import React from 'react';
import { Events } from 'components/events/Events';

export function DayAgenda() {
  return (
    <div className="day-agenda">
      <h2>YOUR EXPERIENCES FOR TODAY</h2>
      <Events daily />
    </div>
  );
}
