import React from 'react';
import { Link } from 'react-router-dom';
import { CarouselItem } from './CarouselItem';

export function Welcome({ ...carouselProps }) {
  return (
    <CarouselItem
      title="Discover the sound of Jamillah"
      text="We searched the globe far and wide to bring you the most exquisite vinyl records from the countries alongside the ancient Spice Route, creating the perfect soundtrack for the desert."
      {...carouselProps}
    >
      <Link to="/library">Explore our collection</Link>
    </CarouselItem>
  );
}
