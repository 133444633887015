import React, { useMemo, useState } from 'react';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { useEhState } from '@foursk/eh-react-hooks';
import { sMobile } from '../../logic/responsive';
import { useEffect } from 'react';

const carouselPropsMobile = {
  infinite: true,
  draggable: false,
};
const carouselPropsDesktop = {
  infinite: true,
  draggable: false,
};

const carouselPropsPortrait = {};

const carouselPropsLandscape = {
  dots: true,
  arrows: true,
};

function getCarouselProps(isMobile) {
  return {
    ...(isMobile ? carouselPropsMobile : carouselPropsDesktop),
    ...(isMobile ? carouselPropsPortrait : carouselPropsLandscape),
  };
}

export function JamillahCarousel({
  additionalClassname,
  backgroundEffect,
  disableInfinity,
  backgroundImageCallback,
  slides,
}) {
  const [slide, setSlide] = useState(1);
  const { isMobile } = useEhState(sMobile);

  useEffect(() => {
    if (backgroundImageCallback) {
      backgroundImageCallback(slide);
    }
  }, [slide, backgroundImageCallback]);

  const rightClick = () => setSlide(slide + 1);
  const leftClick = () => setSlide(slide - 1);
  const hideLeftArrow = disableInfinity && slide === 0;
  const hideRightArrow = disableInfinity && slide === 2;
  const childrenProps = {
    rightClick,
    leftClick,
    backgroundEffect,
    hideLeftArrow,
    hideRightArrow,
  };
  const props = useMemo(() => getCarouselProps(isMobile), [isMobile]);
  const classNameAddition = additionalClassname
    ? ` ${additionalClassname}`
    : '';

  return (
    <Carousel
      className={`jamillah-carousel${classNameAddition}${
        hideLeftArrow ? ' hideLeftArrow' : ''
      }${hideRightArrow ? ' hideRightArrow' : ''}`}
      {...props}
      value={slide}
      onChange={(val) => {
        console.log('val', val);
        if (val === -1) return;
        if (val === 3) return;
        setSlide(val);
      }}
      slides={3}
    >
      {slides.map((C, idx) => (
        <C key={idx} {...childrenProps} />
      ))}
    </Carousel>
  );
}
