import React from 'react';
import { ContentCard } from './ContentCard';
import { windowWidthForRender } from '../../logic/responsive';

/**@typedef {{id:number, name:string, excerpt:string, content_tags:{value:string}[]}} StoryContent */
/**@typedef {{articles:StoryContent[], rawFootages:StoryContent[], sets:StoryContent[], podcasts:StoryContent[]}} StoriesContentData */

const contentTypeToRenderer = {
  article: function ArticleCard(data, idx, avoidColumns, doubleColWidth) {
    return (
      <ContentCard
        key={ idx }
        { ...data }
        contentType="Article"
        avoidColumns={ avoidColumns }
        doubleColWidth={ doubleColWidth }
      />
    );
  },
  rawFootage: function RawFootageCard(data, idx, avoidColumns, doubleColWidth) {
    return (
      <ContentCard
        key={ idx }
        { ...data }
        contentType="Raw Footage"
        avoidColumns={ avoidColumns }
        doubleColWidth={ doubleColWidth }
      />
    );
  },
  set: function SetCard(data, idx, avoidColumns, doubleColWidth) {
    return (
      <ContentCard
        key={ idx }
        { ...data }
        contentType="Set"
        type="set"
        avoidColumns={ avoidColumns }
        doubleColWidth={ doubleColWidth }
      />
    );
  },
  podcast: function PodcastCard(data, idx, avoidColumns, doubleColWidth) {
    return (
      <ContentCard
        key={ idx }
        { ...data }
        contentType="Podcast"
        type="podcast"
        avoidColumns={ avoidColumns }
        doubleColWidth={ doubleColWidth }
      />
    );
  },
};

export const RenderContent = (data, avoidColumns, doubleColWidth) =>
  data.map(({ contentType, ...data }, idx) =>
    contentTypeToRenderer[contentType](data, idx, avoidColumns, doubleColWidth)
  );

export function ContentRenderer(data, avoidColumns, doubleColWidth) {
  const contentEvenness =
    data.length % 2 === 1 && data.length > 2 ? 'odd-pieces' : 'even-pieces';
  const colClass =
    windowWidthForRender > doubleColWidth ? 'double-col' : 'single-col';
  return (
    // <div className="content-renderer-container">
    <div className={`content-renderer ${contentEvenness} ${colClass}`}>
      {RenderContent(data, avoidColumns, doubleColWidth)}
    </div>
    // </div>
  );
}
