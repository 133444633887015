import React from 'react';
import { Link } from 'react-router-dom';
import { CarouselItem } from './CarouselItem';

export function HomepageHistory({ ...carouselProps }) {
  return (
    <CarouselItem
      title="The story of Jamillah"
      text="Learn about our vision, soundsystem & future events"
      {...carouselProps}
    >
      <Link to="/agenda">Read our agenda</Link>
    </CarouselItem>
  );
}
