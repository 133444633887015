import React from 'react';
import { ContentTag } from './ContentTag';

export function CardCover({ cover, contentType }) {
  return (
    <div
      className="card-cover"
      style={{ backgroundImage: cover && `url('${cover.url}')` }}
    >
      <ContentTag contentType={contentType} />
    </div>
  );
}
