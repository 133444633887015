import React from 'react';
import { useEhState } from '@foursk/eh-react-hooks';
import { sLibraryRegion, REGIONS, eLibraryRegion } from 'logic/library';

async function navigateToRegion(region) {
  const payload = { activeRegion: region };
  await eLibraryRegion.fire(payload);
}

function RegionTag({ region, activeRegion, disableClick }) {
  return (
    <div
      className="region-tag-wrapper"
      onClick={disableClick ? undefined : () => navigateToRegion(region)}
    >
      <div
        className={`region-tag${
          activeRegion === region ? ' active-region' : ''
        }`}
      >
        {region}
      </div>
    </div>
  );
}

export function RegionBar({ singleRegion }) {
  const { activeRegion } = useEhState(sLibraryRegion);

  return (
    <div className={`region-bar${singleRegion ? ' singleRegion' : ''}`}>
      {REGIONS.map((region, idx) => (
        <RegionTag
          region={region}
          activeRegion={singleRegion || activeRegion}
          key={idx}
          disableClick={singleRegion}
        />
      ))}
    </div>
  );
}
