import React from 'react';
import { PlayButton } from 'components/PlayButton';
import { ReadButton } from 'assets/read-button';
import { durationStringForStory } from 'logic/utils';
import { useEhState } from '@foursk/eh-react-hooks';
import { sMobile } from 'logic/responsive';

import { useHistory } from 'react-router-dom';
import { CardCover } from './CardCover';

const useIsDoubleCol = (width = 1118) => {
  const { windowWidth } = useEhState(sMobile);
  const doubleCol = windowWidth > width;
  return doubleCol;
};

function ActionsTemplate({ actionButton, actionText, duration, doubleCol }) {
  const durationText = durationStringForStory(duration);

  if (doubleCol) {
    return (
      <div className="actions-template">
        {actionButton}
        <div className="text-duration">
          <div>{actionText}</div>
          <div>{durationText}</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="actions-template text-duration">
        <div>{actionText}</div>
        {actionButton}
        <div className="spacer"></div>
        <div>{durationText}</div>
      </div>
    );
  }
}

function ContentActions({ content, doubleCol }) {
  const { duration_seconds } = content;
  return (
    <ActionsTemplate
      actionText="READ"
      duration={duration_seconds}
      actionButton={<ReadButton />}
      doubleCol={doubleCol}
    />
  );
}

function SoundActions({ content, doubleCol }) {
  const { type, duration_seconds } = content;
  return (
    <ActionsTemplate
      actionText="LISTEN"
      duration={duration_seconds}
      actionButton={<PlayButton piece={content} type={type} style="story" />}
      doubleCol={doubleCol}
    />
  );
}

export function ContentCard(content) {
  const {
    contentType,
    name,
    excerpt,
    description,
    cover,
    id,
    avoidColumns,
    doubleColWidth,
  } = content;
  const { push } = useHistory();
  const doubleColReal = useIsDoubleCol(doubleColWidth);
  const doubleCol = avoidColumns || doubleColReal;
  const colClassName = avoidColumns
    ? ''
    : doubleCol
      ? 'double-col'
      : 'single-col';
  const Actions = contentType === 'Article' ? ContentActions : SoundActions;
  return (
    <div
      className={`content-card ${contentType.toLowerCase()} ${colClassName}`}
      onClick={() => push(`/${contentType.toLowerCase()}/${id}`)}
    >
      <CardCover cover={cover} contentType={contentType} />
      <h3>{name}</h3>
      <div className="text-case">
        <p>{contentType === 'Set' ? description : excerpt}</p>
      </div>
      {/* <ReadMoreReact text={excerpt} readMoreText="..." max={300} /> */}
      <Actions content={content} doubleCol={doubleCol} />
    </div>
  );
}
