import React from 'react';

import soundsystemMain from 'assets/agenda-the-soundsystem-1.jpg';
import pane1 from 'assets/agenda-the-soundsystem-2.jpg';
import pane2 from 'assets/agenda-the-soundsystem-3.jpg';

export function TheSoundsystem() {
  return (
    <div className="thesoundsystem">
      <h2>THE SOUNDSYSTEM</h2>
      <div className="divider soundsystem-divider"></div>
      <img src={soundsystemMain} />
      <div className="panes">
        <div className="pane">
          <p>
            We built a perfect audiophile sound system, custom-made especially
            for Jamillah, to make the listening experience beyond idyllic. The
            sounds of rare authentic vinyl played on our glorious turntable and
            speakers will invite our guests to experience the desert in a way
            they never thought possible. The system is designed to fill the
            space with glorious sound without overpowering the calmness of the
            desert. It is built to love and soothe our guests.
          </p>
          <img src={pane1} />
        </div>
        <div className="pane">
          <img src={pane2} />{' '}
          <p>
            Our team has carefully curated every little detail to make your
            visit to Jamillah an authentic, unforgettable expirience of sight
            and sound.{' '}
          </p>
        </div>
      </div>
    </div>
  );
}
