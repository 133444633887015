import React from 'react';

import { useHistory } from 'react-router-dom';

export function StoryCard({ type, name, excerpt, id: articleId, cover }) {
  const { push } = useHistory();
  return (
    <div className={`story-card ${type}`}>
      <img alt="story" src={cover && cover.url} />
      <div>
        <div>
          <h3>{name}</h3>
          <p>{excerpt}</p>
          <h4 onClick={() => push(`/article/${articleId}`)}>READ MORE</h4>
        </div>
      </div>
    </div>
  );
}
