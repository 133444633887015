import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Loader } from './Loader/Loader';
import { apollo, gquery } from '../logic/graphql';
import { useState } from 'react';
import { useEffect } from 'react';

function Render(error, loading, dataToComponent, data) {
  if (error) {
    return <div>ERROR: {JSON.stringify(error)}</div>;
  }

  if (loading) return <Loader />;
  else {
    return dataToComponent(data);
  }
}

/**
 *
 * @param {string} query
 * @param {(data:any)=>React.Component} dataToComponent
 */
export function useApollo(
  dataToComponent,
  query,
  transformData = undefined,
  dataCallback = undefined
) {
  // console.log('query', query);
  const gqlQuery = gquery(query);
  const [isLoading, setIsLoading] = useState(true);
  const [transformedData, setTransformedData] = useState();

  const { loading, error, data } = useQuery(gqlQuery, { client: apollo });

  useEffect(() => {
    if (data) {
      if (dataCallback) dataCallback(data);
      setTransformedData(transformData ? transformData(data) : data);
      setIsLoading(false);
    }
  }, [data, dataCallback]);

  return Render(error, loading || isLoading, dataToComponent, transformedData);
}

export const makeMappedDataToComponent =
  (fieldInData, mappedDataToComponent, modifyData = (data) => data) =>
    (data) =>
      modifyData(data[fieldInData]).map(mappedDataToComponent);

export function mergeDataToComponents(...dataToComponents) {
  return function (data) {
    return {
      ...dataToComponents.map((dataToComponent) => dataToComponent(data)),
    };
  };
}
