import React from 'react';

export function PrevButton({ ...props }) {
  return (
    <svg width="27" height="17" viewBox="0 0 27 17" {...props}>
      <path
        d="M1.5 9.36602C0.833332 8.98112 0.833333 8.01888 1.5 7.63397L11.25 2.00481C11.9167 1.61991 12.75 2.10103 12.75 2.87083L12.75 14.1292C12.75 14.899 11.9167 15.3801 11.25 14.9952L1.5 9.36602Z"
        fill="#1C1C1C"
      />
      <path
        d="M11.5 9.36602C10.8333 8.98112 10.8333 8.01888 11.5 7.63397L21.25 2.00481C21.9167 1.61991 22.75 2.10103 22.75 2.87083L22.75 14.1292C22.75 14.899 21.9167 15.3801 21.25 14.9952L11.5 9.36602Z"
        fill="#1C1C1C"
      />
    </svg>
  );
}
