import React from 'react';

const defaultFill = '#1C1C1C';
// const defaultFill = "yellow";

export function Logo({...props}) {
  const fillColor = defaultFill;  
  return (
    
    <svg
      className="logo"
      // width="238"
      // height="54"
      viewBox="0 0 238 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M154.802 34.0605C154.802 34.0605 151.706 39.4307 154.719 41.7657C158.33 44.5617 162.232 39.9856 167.527 34.8844C159.759 40.9247 158.867 38.9568 158.867 38.9568C158.867 38.9568 158.196 38.2311 160.907 32.1054C160.907 32.1054 170.904 7.73928 183.868 0.695801C181.049 0.704338 177.196 2.46734 174.567 4.41817C172.151 6.21106 167.912 10.6975 166.28 13.2076C161.041 21.2628 154.802 34.0605 154.802 34.0605Z"
          fill={fillColor}
        />
        <path
          d="M139.833 34.1033C139.833 34.1033 136.737 39.4734 139.751 41.8084C143.362 44.6045 147.263 40.0283 152.559 34.9271C144.791 40.9675 143.899 38.9996 143.899 38.9996C143.899 38.9996 143.228 38.2739 145.938 32.1482C145.938 32.1482 155.936 7.78201 168.9 0.738525C166.081 0.747063 162.227 2.51007 159.599 4.4609C157.183 6.25378 152.944 10.7403 151.312 13.2503C146.072 21.3055 139.833 34.1033 139.833 34.1033Z"
          fill={fillColor}
        />
        <path
          d="M136.317 20.7163C135.131 22.8166 131.94 27.9306 129.437 31.9432C126.935 35.9559 127.069 38.3933 127.411 38.7519C127.753 39.1105 128.316 40.7454 136.17 34.6325C130.814 39.7935 126.857 44.4336 123.215 41.5949C120.483 39.4648 123.298 33.8001 123.298 33.8001C123.298 33.8001 127.459 26.8506 132.382 18.9875C132.382 18.9875 129.442 19.0216 124.783 22.6501C124.809 22.4964 124.848 21.2884 124.848 21.2884C124.848 21.2884 130.585 15.3462 136.058 15.3334C138.357 15.3206 137.504 18.6161 136.317 20.7163Z"
          fill={fillColor}
        />
        <path
          d="M60.7653 31.3286C59.9037 32.8653 59.2628 33.9197 59.2628 33.9197C59.2628 33.9197 56.3618 39.3966 59.4577 41.6249C63.1641 44.2928 66.9007 39.5844 72.01 34.2996C64.4673 40.6089 63.5018 38.6709 63.5018 38.6709C63.5018 38.6709 62.2851 37.8214 66.0478 31.2816"
          fill={fillColor}
        />
        <path
          d="M59.8301 32.972C54.9071 38.2952 51.1401 42.5383 48.8669 42.6237C46.5937 42.7091 43.8702 31.1492 54.2576 23.696C54.1104 24.6436 49.5943 34.2441 52.1143 37.8341C55.8986 34.7564 66.9138 22.3769 66.9138 22.3769C66.9138 22.3769 63.5278 15.739 56.8945 19.9864C56.9161 19.6406 56.9378 18.8637 56.9378 18.8637C56.9378 18.8637 64.1167 11.8757 70.4686 18.7186C72.6292 21.6469 71.0618 23.7643 71.0618 23.7643C71.0618 23.7643 67.7928 28.2892 66.0435 31.2773"
          fill={fillColor}
        />
        <path
          d="M182.959 31.3286C182.097 32.8653 181.456 33.9197 181.456 33.9197C181.456 33.9197 178.555 39.3966 181.651 41.6249C185.358 44.2928 189.094 39.5844 194.203 34.2996C186.661 40.6089 185.695 38.6709 185.695 38.6709C185.695 38.6709 184.479 37.8214 188.241 31.2816"
          fill={fillColor}
        />
        <path
          d="M182.023 32.972C177.1 38.2952 173.333 42.5383 171.06 42.6237C168.787 42.7091 166.064 31.1492 176.451 23.696C176.304 24.6436 171.788 34.2441 174.308 37.8341C178.092 34.7564 189.107 22.3769 189.107 22.3769C189.107 22.3769 185.721 15.739 179.088 19.9864C179.109 19.6406 179.131 18.8637 179.131 18.8637C179.131 18.8637 186.31 11.8757 192.662 18.7186C194.823 21.6469 193.255 23.7643 193.255 23.7643C193.255 23.7643 189.986 28.2892 188.237 31.2773"
          fill={fillColor}
        />
        <path
          d="M135.343 7.66672C135.343 7.66672 137.266 6.83004 140.409 6.78308C140.972 8.70403 141.786 11.4745 141.786 11.4745C141.786 11.4745 139.309 11.3037 136.763 12.5118C136.356 11.133 135.343 7.66672 135.343 7.66672Z"
          fill={fillColor}
        />
        <path
          d="M221.884 5.90378C228.968 1.65635 238 1.78868 238 1.78868C238 1.78868 220.793 -3.07772 210.895 10.1683C203.777 19.6962 201.499 28.8698 197.278 35.1065C197.065 35.401 196.36 36.3657 195.342 37.5824C194.307 38.8246 192.952 40.3272 191.476 41.6505C190.519 42.5085 189.514 43.2897 188.51 43.8745C188.488 43.8959 188.553 43.8788 188.696 43.8318C188.756 43.8105 188.83 43.7806 188.917 43.7507C188.999 43.7166 189.094 43.6824 189.202 43.6397C189.267 43.6141 189.332 43.5885 189.401 43.5586C189.601 43.4775 189.83 43.3836 190.077 43.2769C190.484 43.1019 190.943 42.9012 191.432 42.6793C192.956 42.1243 194.433 41.3474 195.866 40.4125C196.018 40.3186 196.156 40.229 196.29 40.1436C196.299 40.1351 196.308 40.1308 196.316 40.1265C196.442 40.0412 196.563 39.9558 196.671 39.8747C196.68 39.8661 196.689 39.8619 196.702 39.8533C197.377 39.3795 198.04 38.8715 198.698 38.3337C198.745 38.2952 198.75 38.2952 198.75 38.2952V38.291C205.963 32.3958 211.315 23.2521 217.745 19.4614C221.988 21.2287 212.752 33.8344 212.752 33.8344C212.752 33.8344 209.657 39.2045 212.67 41.5395C216.281 44.3356 220.183 39.7594 225.478 34.6582C217.71 40.6986 216.818 38.7307 216.818 38.7307C216.818 38.7307 215.06 37.4159 221.256 28.413C228.678 16.311 221.499 15.6152 221.499 15.6152C221.499 15.6152 217.45 15.2054 207.05 26.3854C212.233 16.93 215.783 9.56212 221.884 5.90378Z"
          fill={fillColor}
        />
        <path
          d="M119.505 20.7591C120.691 18.6589 121.544 15.3677 119.249 15.372C115.616 15.3805 108.182 19.4358 103.584 22.1593C103.9 21.63 104.172 21.1647 104.38 20.7976C105.567 18.6973 106.42 15.4061 104.125 15.4104C100.665 15.4189 93.7462 19.1029 89.1262 21.805C89.3427 21.4379 89.5332 21.1092 89.6891 20.836C90.8755 18.7357 91.7284 15.4445 89.4336 15.4488C83.965 15.4616 75.1537 23.6662 75.1537 23.6662C75.1537 23.6662 74.8679 24.8273 74.842 24.981C82.5015 19.1669 85.7532 19.1029 85.7576 19.1029C84.818 18.9705 78.9423 30.8292 78.0634 32.2123C75.3356 36.5109 72.0059 41.2365 67.4812 43.8746C67.4595 43.8959 67.5245 43.8788 67.6674 43.8319C67.728 43.8105 67.8016 43.7807 67.8882 43.7508C67.9705 43.7166 68.0657 43.6825 68.174 43.6398C68.2389 43.6142 68.3039 43.5886 68.3731 43.5587C68.5723 43.4776 68.8018 43.3837 69.0486 43.2769C69.4556 43.1019 69.9146 42.9013 70.4038 42.6793C71.0663 42.4403 71.7158 42.15 72.3566 41.8298C77.2104 39.2515 81.7957 33.4758 83.3415 31.4097H83.3242C83.5147 31.1579 83.7009 30.9103 83.8914 30.6542C87.5545 25.7365 92.9451 22.0867 98.6216 19.756C99.2235 19.5084 99.834 19.2651 100.453 19.0687C95.5214 26.9318 91.3604 33.8814 91.3604 33.8814C91.3604 33.8814 88.5417 39.546 91.2738 41.6761C94.9152 44.5149 98.8727 39.879 104.229 34.7138C96.3701 40.8224 95.8115 39.1917 95.4695 38.8331C95.2053 38.5557 95.0711 37.0146 96.1969 34.4619C96.2012 34.4662 96.2012 34.4747 96.2012 34.479C100.704 25.1816 110.745 20.6823 114.049 19.4145C114.989 19.1626 115.564 19.026 115.564 19.026C110.641 26.8891 106.48 33.8387 106.48 33.8387C106.48 33.8387 103.662 39.5033 106.398 41.6335C110.039 44.4722 113.997 39.8363 119.353 34.6711C111.494 40.7797 110.936 39.149 110.594 38.7905C110.252 38.4319 110.122 35.9944 112.62 31.9818C115.127 27.9734 118.318 22.8594 119.505 20.7591Z"
          fill={fillColor}
        />
        <path
          d="M61.2807 1.71241C61.2547 1.61423 61.1595 1.4947 60.9863 1.4819C58.4273 1.31541 55.4051 1.05929 52.7379 0.833043L52.6686 0.828774C46.6198 0.316521 40.3674 -0.208538 37.7089 0.0817387C34.7992 0.397628 33.2188 1.67399 32.4091 2.68996C31.5864 3.71873 30.8417 5.41344 31.2141 7.0868C31.3483 7.68443 31.699 8.31621 32.2056 8.9053C32.4351 9.16996 32.6906 9.42182 32.9763 9.65233C33.786 10.3055 34.9464 10.7836 36.0116 10.5957C36.0116 10.5957 35.3448 10.3396 35.1456 10.2414C34.9378 10.1432 34.7429 10.0152 34.5567 9.87858L33.6128 8.97787C32.2576 7.12949 32.8508 5.4049 34.8382 4.42735C38.7221 2.51921 44.0738 3.53518 48.2175 3.85107L48.5552 3.87668C49.0835 3.9151 49.6117 3.95779 50.1356 3.99194C51.1272 4.06024 52.123 4.15415 52.9977 4.23953C46.8839 7.72285 43.5672 13.379 39.627 22.5227L39.5837 22.6251C36.28 30.6419 33.7644 36.7548 30.5819 41.3181C30.045 42.0908 29.4865 42.8207 28.9019 43.5037C28.4343 43.9776 27.9277 44.4172 27.4211 44.8398C24.7236 47.3926 20.6708 49.2495 16.4925 50.5899C13.9379 51.4479 11.4266 52.084 9.78988 52.4596C6.62041 53.1896 2.4724 53.5609 0.0173626 53.8341C-0.00861661 53.8384 -0.00428673 53.8726 0.0216925 53.8768C4.52476 54.0988 8.82432 54.0433 13.1932 53.5311C18.6791 52.8865 24.0308 51.3241 28.7461 48.4427C29.283 48.114 29.8199 47.7682 30.3351 47.4139C37.146 43.2988 39.8998 38.2958 45.2558 24.3497C49.6723 12.871 52.8245 6.60016 57.7865 4.19684C58.1502 4.01328 58.5356 3.85534 58.9729 3.7102L59.0292 3.68032C61.3846 2.10941 61.3326 1.90877 61.2807 1.71241Z"
          fill={fillColor}
        />
        <path
          d="M16.8518 50.7985C21.0085 49.394 25.031 47.4774 27.6895 44.8862L27.6808 44.882C25.7194 46.5254 23.4982 47.7933 21.6667 48.7537C20.1988 49.5264 18.5188 50.2094 16.8518 50.7985Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0H238V54H0V0Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
