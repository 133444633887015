import axios from 'axios';

// const TOKEN =
//   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaXNBZG1pbiI6dHJ1ZSwiaWF0IjoxNTkyNzQ3ODMxLCJleHAiOjE1OTUzMzk4MzF9.FOE5OlJVeNzph_i6rHZ47xXX1HND0ngYa-uEkeRSvQY';

export const makeAuthorizationHeader = (TOKEN) => ({
  Authorization: `Bearer ${TOKEN}`,
});

export let AuthorizationHeader;

const URLS = {
  DEV: 'https://dev-strapi.barjamillah.com',
  ORAN: 'http://localhost:1337'
};

export const API_BASE_URL = URLS.DEV;

axios.defaults.baseURL = API_BASE_URL;

export const client = axios;

export async function login() {
  const {
    data: { jwt },
  } = await axios.post('/auth/local', {
    identifier: 'clientweb',
    password: 'TrPDMm3uihnMLDN',
  });

  AuthorizationHeader = makeAuthorizationHeader(jwt);
  Object.assign(axios.defaults.headers, AuthorizationHeader);
}
