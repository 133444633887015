import React from 'react';
import { VolumeIcon } from 'assets/volume-icon';
import Slider from 'rc-slider';
import { setVolume, sPlayer } from 'logic/player';
import { useEhState } from '@foursk/eh-react-hooks';

const volumeCallback = (v) => setVolume(v / 100);

export function Volume() {
  const { volume } = useEhState(sPlayer);
  return (
    <div className="volume">
      <VolumeIcon />
      <div className="slider-container">
        <Slider
          className="volume-slider"
          onChange={volumeCallback}
          defaultValue={volume * 100}
          value={volume * 100}
          min={0}
          max={100}
        />
      </div>
    </div>
  );
}
