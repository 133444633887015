import React, { useEffect } from 'react';
import { useEhState } from '@foursk/eh-react-hooks';

import { ButtonBracket, ButtonRound } from 'components/Buttons';
import { StoryCard } from './sub/StoryCard';
import { sMobile } from 'logic/responsive';
import { useHistory } from 'react-router-dom';
import { playRandomRecord } from 'logic/player';
import { JamillahCarousel } from '../../components/carousel/JamillahCarousel';

import carouselBg from '../../assets/homepage-bg.jpg';
import { useApollo } from '../../components/useApollo';
import { Q_HOMEPAGE_STORIES } from '../../logic/stories';
import { HomepageHistory } from './sub/History';
import { Unlock } from './sub/Unlock';
import { Welcome } from './sub/Welcome';
import ReactGA from 'react-ga';

function HomepageStories(data) {
  const {
    homepageStory: { article_top, article_bottom },
  } = data;
  return [
    <StoryCard key={0} type="up" {...article_top} />,
    <StoryCard key={1} type="down" {...article_bottom} />,
  ];
}

export function Homepage() {
  const { isMobile } = useEhState(sMobile);
  const { push } = useHistory();
  const homepageStories = useApollo(HomepageStories, Q_HOMEPAGE_STORIES());

  useEffect(() => {
    const gapath = window.location.pathname;
    console.log('pv ', gapath);
    ReactGA.pageview(gapath);
  }, []);

  return (
    <div className="homepage">
      {isMobile && (
        <div
          className="mobile-carousel-bg"
          style={{ backgroundImage: `url(${carouselBg})` }}
        ></div>
      )}
      <JamillahCarousel slides={[Unlock, Welcome, HomepageHistory]} />
      <div className="intro">
        {isMobile && <div className="divider-icon"></div>}
        <div className="text">
          <p>
            Located in the heart of Six Senses Shaharut Jamillah showcases a
            unique collection of musical artifacts. Shelves stacked with rare
            original vinyl records telling the story of this desert{'\''}s deep
            musical heritage. Hear the whispers of these ancient trade routes
            sharing musical secrets from across the globe.
          </p>
        </div>
        <div className="divider-icon"></div>
        <div className="buttons">
          <ButtonBracket
            miniText="EXPLORE"
            mainText="OUR LIBRARY"
            onClick={() => push('/library')}
          />
          <ButtonRound
            miniText="PLAY"
            mainText="RANDOM RECORD"
            onClick={playRandomRecord}
          />
        </div>
      </div>

      <div className="the-stories">
        <h2>THE STORIES</h2>
        <div className="stories-divider divider"></div>
        {homepageStories}
        <h3 onClick={() => push('/stories')}>VIEW ALL STORIES</h3>
      </div>

      {false === isMobile && (
        <div className="actions">
          <ButtonBracket
            miniText="EXPLORE"
            mainText="OUR LIBRARY"
            onClick={() => push('/library')}
          />
          <ButtonRound
            miniText="PLAY"
            mainText="RANDOM RECORD"
            onClick={playRandomRecord}
          />
          <ButtonBracket
            miniText="EXPLORE"
            mainText="OUR LIBRARY"
            onClick={() => push('/library')}
          />
        </div>
      )}
    </div>
  );
}
