import React from 'react';
import './Loader.css';

import Lottie from 'react-lottie';
import loadingAnimation from '../../assets/Jamillah_Loader.json';

const animationOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const getHeightOrWidth = (props, field) =>
  (props && props[field]) ||
  (props && props.style && props.style[field]) ||
  240;

export function Loader({ ...props }) {
  const width = getHeightOrWidth(props, 'width');
  const height = getHeightOrWidth(props, 'height');
  return (
    <div {...props} className="lds-ring">
      <Lottie options={animationOptions} width={width} height={height} />
    </div>
  );
}
