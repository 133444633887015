import React from 'react';
import CloseButtonSvg from 'assets/close-menu.svg';
import MobileSunLogo from 'assets/sun-logo-mobile.svg';

export function CloseButton({ onClose }) {
  return <img src={CloseButtonSvg} className="overlay-x" onClick={onClose} />;
}

export function Sun() {
  return <img src={MobileSunLogo} className="overlay-sun" />;
}

export function OverlayEgg({ children }) {
  return <div className="overlay-egg">{children}</div>;
}

export function TopStrip({ onClose, rightFlank }) {
  return (
    <div className="top-strip">
      <div className="flank">
        <CloseButton onClose={onClose} />
      </div>
      <div className="center">
        <Sun />
      </div>
      <div className="flank">
        {rightFlank}
      </div>
    </div>
  );
}
