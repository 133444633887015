import React, { useMemo } from 'react';
import { Logo } from 'assets/logo';
import { HeaderLinks } from './HeaderLinks';
import { ShaharutGuests } from './ShaharutGuest';
import { sMobile } from 'logic/responsive';
import { useEhState } from '@foursk/eh-react-hooks';
import { LogoStar } from 'assets/logoStar';
import { useRef } from 'react';
import { eCustomScroll } from '../../logic/custom-scroll';
import { useState } from 'react';
import { useHistoryWA } from '../../logic/history';
import { sLoggedIn } from '../../logic/login';

function LogoBundle() {
  const { push, pathname } = useHistoryWA();
  const { portrait } = useEhState(sMobile);
  const { ipAllowed } = useEhState(sLoggedIn);

  if (portrait && pathname === '/' && ipAllowed) {
    return (
      <div className="logo-bundle">
        <div className="logo-elements">
          <LogoStar />
          <Logo onClick={() => push('/')} />
          <LogoStar />
        </div>
        <h3>BAR & MUSIC SHRINE</h3>
      </div>
    );
  } else {
    return <Logo onClick={() => push('/')} />;
  }
}

function makeKeyEventsPackage(
  applyDragScroll,
  setApplyDragScroll,
  posY,
  setPosY,
  portrait
) {
  const onDown = (e) => {
    const newPosY = e.screenY || e.touches[0].pageY;
    // console.log('DOWN', {newPosY});
    setApplyDragScroll(true);
    setPosY(newPosY);
  };

  const onDrag = (e) => {
    if (applyDragScroll) {
      const newPosY = e.screenY || e.touches[0].pageY;
      const delta = posY - newPosY;
      setPosY(newPosY);
      // console.log('DRAG', { newPosY, delta });
      eCustomScroll.fire({ scrollDelta: delta });
    }
  };

  const onUp = () => {
    setApplyDragScroll(false);
  };

  const keyEventsPackage = portrait
    ? {
      onTouchStart: onDown,
      onTouchMove: onDrag,
      onTouchEnd: onUp,
    }
    : {
      // onMouseDown: onDown,
      // onMouseMove: onDrag,
      // onMouseUp: onUp,
    };
  return keyEventsPackage;
}

export function Header() {
  const headerRef = useRef();
  const { portrait } = useEhState(sMobile);
  const [applyDragScroll, setApplyDragScroll] = useState(false);
  const [posY, setPosY] = useState(0);

  const keyEventsPackage = useMemo(
    () =>
      makeKeyEventsPackage(
        applyDragScroll,
        setApplyDragScroll,
        posY,
        setPosY,
        portrait
      ),
    [applyDragScroll, setApplyDragScroll, posY, setPosY, portrait]
  );

  const { ipAllowed } = useEhState(sLoggedIn);

  return (
    <div className="header" ref={headerRef} {...keyEventsPackage}>
      {ipAllowed && <HeaderLinks />}
      {false === ipAllowed && false === portrait && (
        <div className="ip-disallowed-padder" />
      )}
      <div className="spacer">
        <LogoBundle />
      </div>
      {ipAllowed && <ShaharutGuests />}
    </div>
  );
}
