// 3rd party
import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';

// logic
import { Q_STORIES_ID, Q_STORY } from '../../logic/stories';
import { useEhState } from '@foursk/eh-react-hooks';
import { sMobile } from '../../logic/responsive';
import { query } from '../../logic/graphql';
import { arrayNameToContentType } from '../../logic/utils';

// components
import { ContentTag } from '../../components/stories/ContentTag';
import { PlayButton } from '../../components/PlayButton';
import { Loader } from '../../components/Loader/Loader';
import { ContentRenderer } from '../../components/stories/ContentRenderer';
import { getRelatedContent } from '../../logic/content';

import ReactGA from 'react-ga';

function Credits({ creditsText, author }) {
  return (
    <div className="credits">
      <div>
        <span>{creditsText} by </span>
        <span>{author}</span>
      </div>
      {/* <div>
        <span>Images by </span>
        <span>{author}</span>
      </div> */}
    </div>
  );
}

function ArticleContent({ cover, body }) {
  return (
    <div className="article-content">
      {cover && <img src={cover.url} />}
      <div className="content">
        <ReactMarkdown source={body} />
      </div>
      <div className="story-divider"></div>
    </div>
  );
}

function RelatedContent({ tagId, tagName, except }) {
  const { windowWidth, portrait } = useEhState(sMobile);
  const [content, setContent] = useState();
  const [relatedPieces, setRelatedPieces] = useState(3);

  useEffect(() => {
    let newVal = 3;
    if (windowWidth < 700) newVal = 1;
    else if (windowWidth < 1000) newVal = 2;

    if (newVal !== windowWidth) setRelatedPieces(newVal);
  }, [windowWidth]);

  // const [slide, setSlide] = useState(0);
  // const rightClick = () => setSlide(slide + 1);
  // const leftClick = () => setSlide(slide - 1);

  useEffect(() => {
    if (tagId !== '')
      getRelatedContent(tagId, except, relatedPieces).then(setContent);
  }, [tagId]);

  if (!content) return <Loader />;
  if (content.length === 0) return null;

  // const slice = windowWidth < 1100 && false === isMobile ? 2 : content.length;
  // const renderedContent = isMobile ? ContentRenderer(content) : ContentCarousel(content, {slide, setSlide, rightClick, leftClick});

  return (
    <div className="related-content">
      <h2 className="title">RELATED CONTENT</h2>
      <div className="divider"></div>
      <h2 className="tag-name">{tagName}</h2>
      {/* {renderedContent} */}
      {ContentRenderer(content.slice(0, relatedPieces), !portrait)}
    </div>
  );
}

function Story({
  contentType,
  creditsText,
  authorField,
  showPlayButton,
  data,
}) {
  const {
    [authorField]: author,
    name,
    excerpt,
    description,
    body,
    cover,
    content_tags,
    id,
  } = data;
  const { id: contentTagId, value: contentTagName } =
    content_tags && content_tags.length > 0
      ? content_tags[0]
      : { id: '', value: '' };
  return (
    <div className={`story-page ${contentType}-story`}>
      <ContentTag contentType={contentType} />
      <h2 className="stories-title">STORIES</h2>
      <div className="divider stories-divider"></div>
      <h1>{name}</h1>
      <Credits creditsText={creditsText} author={author} />
      <div className="excerpt">{description || excerpt}</div>
      {showPlayButton && (
        <PlayButton
          piece={{ ...data, type: contentType.toLowerCase() }}
          type={contentType}
          style="story"
        />
      )}
      <div className="story-divider"></div>
      {body && <ArticleContent cover={cover} body={body} />}
      <RelatedContent
        tagId={contentTagId}
        tagName={contentTagName}
        except={{ contentType, id }}
      />
    </div>
  );
}

function SoundContentPage(data, contentType, key) {
  return (
    <Story
      authorField="creator"
      contentType={contentType}
      creditsText="Recorded"
      data={data}
      showPlayButton
      key={key}
    />
  );
}

function Set(data, key) {
  return SoundContentPage(data, 'set', key);
}

function Podcast(data, key) {
  return SoundContentPage(data, 'podcast', key);
}

function Article(data, key) {
  return (
    <Story
      authorField="author"
      contentType="article"
      creditsText="Written"
      data={data}
      key={key}
    />
  );
}

export function StoryFeed({ firstContentType }) {
  const { id } = useParams();
  const loaderId = 'story-feed-loader';
  const [stories, updateStories] = useState([
    { id, contentType: firstContentType },
  ]);
  const [feed, updateFeed] = useState([]);

  useEffect(() => {
    const gapath = window.location.pathname;
    console.log('pv ', gapath);
    ReactGA.pageview(gapath);
  }, []);

  useEffect(() => {
    updateFeed([]);
    const innerFeed = [];
    const innerStories = [{ id, contentType: firstContentType }];

    const getNextInFeed = () => {
      if (innerStories.length === 1) {
        return;
      }
      const idx = innerFeed.length;
      const story = innerStories[idx];
      innerFeed.push({ ...story });

      query(Q_STORY(story.contentType, story.id)).then(({ data }) => {
        const newItem = { ...data[story.contentType], ...story };
        innerFeed[idx] = newItem;
        // innerFeed.push(newItem);
        updateFeed([].concat(innerFeed));
      });
    };

    query(Q_STORIES_ID())
      .then((storiesObject) => {
        Object.entries(storiesObject.data).forEach(
          ([contentTypeOrig, data]) => {
            const contentType = arrayNameToContentType(contentTypeOrig);
            data.forEach((i) => {
              if (
                i.id === id ||
                innerStories.find(
                  (is) => is.id === i.id && is.contentType === contentType
                )
              ) {
                return;
              }

              innerStories.push({
                id: i.id,
                contentType,
              });
            });
          }
        );

        updateStories(innerStories);
      })
      .then(getNextInFeed);

    const elem = document.getElementById(loaderId);
    const observer = new IntersectionObserver(getNextInFeed);
    observer.observe(elem);

    return () => observer.disconnect();
  }, [id, firstContentType]);

  // console.log('feed stories', { feed, stories });
  return (
    <div className={'story-feed'}>
      {feed.map((data, key) => {
        switch (data.contentType) {
        case 'set':
          return Set(data, key);
        case 'podcast':
          return Podcast(data, key);
        case 'article':
        default:
          return Article(data, key);
        }
      })}
      {feed.length < stories.length && <Loader id={loaderId} />}
    </div>
  );
}
