import React, { useState } from 'react';
import { CardCover } from '../stories/CardCover';
import { PlayTitle } from '../interactive-content/PlayTitle';
import { ExploreButton } from '../interactive-content/ExploreButton';

function InteractiveContent({ piece }) {
  return (
    <div className="static-podset-data">
      <PlayTitle
        piece={piece}
        name={piece.name}
        artist={piece.artist || piece.creator}
      />
      <div className="content-container">
        <div className="description">{piece.description} </div>
      </div>
    </div>
  );
}

export function SetPodContent({ piece }) {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div className={`content podset-content${isExpanded ? ' expanded' : ''}`}>
      <div className="interactive-container">
        <CardCover contentType={piece.contentType} cover={piece.cover} />
        <InteractiveContent piece={piece} />
      </div>
      <ExploreButton
        onClick={() => setIsExpanded(!isExpanded)}
        moreText="MORE"
        lessText="LESS"
      />
    </div>
  );
}
