import React from 'react';
import { dateStr, datePlusMonthStr, getHourString, addGetDateObj } from 'logic/utils';
import { Q_EVENTS_TODAY, Q_EVENT_NEXT_MONTH } from 'logic/events';
import { useApollo } from 'components/useApollo';
import { makeMappedDataToComponent } from 'components/useApollo';

/**
 *
 * @param {{daily:Boolean, event:Object}} param0
 */
function Event({ daily, event }) {
  const { name, location, dateObj, isNow, isOver } = event;
  const timeForDisplay = daily ? getHourString(dateObj) : dateStr(dateObj);
  return (
    <div className={`event ${isNow ? 'now': ''} ${isOver ? 'over' : ''}`}>
      <span className="time-location">{ timeForDisplay } • at { location }</span>
      <span className="name">{ name }</span>
    </div>
  );
//   return <div className="event"></div>;
}

function DayEvent(event) {
  return <Event key={ event.id } event={event} daily />;
}

function MonthEvent(event) {
  return <Event key={ event.id } event={event} daily={false} />;
}

function DateDisplay({ daily }) {
  const content = daily ? dateStr() : `${dateStr()}-${datePlusMonthStr()}`;
  return <div className="date-display">{content}</div>;
}

function enrichEvents(events) {
  const now = new Date();
  for (let i = 0; i < events.length; i++) {
    const event = events[i];
    addGetDateObj(event);
    if (now > event.dateObj) {
      if (i === events.length - 1 || now > addGetDateObj(events[i + 1])) {
        event.isOver = true;
      }
      else {
        event.isNow = true;
      }
    }
  }
  return events;
}

/**
 *
 * @param {{daily:Boolean}} param0
 */
export function Events({ daily }) {
  const [query, comp] = daily ? [Q_EVENTS_TODAY(), DayEvent] : [Q_EVENT_NEXT_MONTH(), MonthEvent];
  const events = useApollo(makeMappedDataToComponent('events', comp, enrichEvents), query);

  return <div className="events">
    <DateDisplay daily={ daily } />
    {events}
  </div>;
}
