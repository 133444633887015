import React from 'react';
import { sPlayerPopup } from '../../logic/player';
import { useEhState } from '@foursk/eh-react-hooks';
import { Album } from '../../pages/library/Album';
import { useEffect } from 'react';
import { getAlbum } from '../../logic/library';
import { Loader } from '../Loader/Loader';
import { useState } from 'react';
import { RegionBar } from '../../pages/library/RegionBar';
import { sMobile } from '../../logic/responsive';

import OpenLibrary from 'assets/open-library.svg';
import OpenStories from 'assets/open-stories.svg';
import { PlayNextPrev } from './PlayNextPrev';
import { Volume } from './Volume';
import { SeekableProgress } from './SeekableProgress';
import { SetPodContent } from './SetPodContent';
import { useHistory } from 'react-router-dom';

const isAlbum = (piece) => !!piece.album;

const makeStoryLink = (piece) =>
  `/${piece.contentType.toLowerCase()}/${piece.id}`;
const makeLibraryLink = (piece) => `/library/${piece.album.id}`;

const [LibraryImageText, StoriesImageText] = [
  ['OPEN LIBRARY', OpenLibrary, makeLibraryLink],
  ['OPEN STORIES', OpenStories, makeStoryLink],
];

function PageLink({ piece }) {
  const { push } = useHistory();

  const [text, image, makeLink] = isAlbum(piece)
    ? LibraryImageText
    : StoriesImageText;
  return (
    <div
      className="page-link"
      onClick={() => {
        push(makeLink(piece));
        sPlayerPopup.fire({ on: false });
      }}
    >
      <img src={image} />
      <span>{text}</span>
    </div>
  );
}

function PopupControls({ piece }) {
  return (
    <div className="popup-controls">
      <SeekableProgress />
      <PageLink piece={piece} />
      <PlayNextPrev />
      <Volume />
    </div>
  );
}

function AlbumContent({ piece }) {
  const [album, setAlbum] = useState(piece.album);

  useEffect(() => {
    if (piece.album.records) return;

    getAlbum(piece.album.id).then(setAlbum);
  }, [piece]);

  if (!album.records) {
    return <Loader />;
  }
  return (
    <div className="content album-content">
      <Album
        album={album}
        // scrollContainerSelector=".player-popup"
        disableRegionBarIntegration
      />
      <div className="popup-spacer"></div>
      <RegionBar singleRegion={album.geo_tag.value} />
    </div>
  );
}

function Content({ piece }) {
  if (!piece) return null;
  return isAlbum(piece) ? (
    <AlbumContent piece={piece} />
  ) : (
    <SetPodContent piece={piece} />
  );
}

function Header({ openPlayerButton }) {
  const { isMobile } = useEhState(sMobile);

  return (
    <div className="player-popup-header">
      {!isMobile && <span className="evener"></span>}
      <span className="now-playing">NOW PLAYING</span>
      {openPlayerButton}
    </div>
  );
}

export function PlayerPopup({ open, openPlayerButton, piece, playerEl }) {
  return (
    <div className={`player-popup${open ? ' open' : ''}`}>
      <Header openPlayerButton={openPlayerButton} />
      <div className="inner-area">
        <Content piece={piece} />
        <div className="spacer"></div>
        <PopupControls piece={piece} playerEl={playerEl} />
      </div>
    </div>
  );
}
