import React, { useState, useEffect } from 'react';
import { Q_RECORD_OF_THE_DAY_ID, Q_ALBUM_BY_ID } from '../../logic/library';
import { query } from '../../logic/graphql';
import { Loader } from '../Loader/Loader';
import { PlayButton } from 'components/PlayButton';
import { useEhState } from '@foursk/eh-react-hooks';
import { sMobile } from 'logic/responsive';
import { useHistoryWA } from '../../logic/history';

function Year({ year }) {
  return (
    <div className="year">
      {year}
      <span></span>
    </div>
  );
}

function Description({ description }) {
  return <span className="description">{description}</span>;
}

function ExploreAlbum({ albumId }) {
  const { push } = useHistoryWA();

  return (
    <div className="explore-album" onClick={() => push(`/library/${albumId}`)}>
      EXPLORE ALBUM
    </div>
  );
}

export function RecordOfTheDay() {
  const [album, setAlbumData] = useState();
  const { isMobile } = useEhState(sMobile);

  useEffect(() => {
    query(Q_RECORD_OF_THE_DAY_ID)
      .then(({ data }) => {
        const { id } = data.recordOfTheDay.album;
        return query(Q_ALBUM_BY_ID(id));
      })
      .then(({ data }) => {
        if (data.album) {
          data.album.records = data.records;
          data.records.forEach(r => r.album = data.album);
          setAlbumData(data.album);
        }
      });
  }, []);

  if (!album) {
    return <Loader />;
  } else {
    console.log('albumData', album);
    const { name, cover, description, artist, year } = album;

    const metadata = isMobile ? (
      <div className="metadata">
        <div>
          <Year year={year} />
          <PlayButton piece={album} type="record" style="floating" />
        </div>
        <div>
          <div className="name">{name}</div>
          <div className="artist">{artist}</div>
          <Description description={description} />
          <ExploreAlbum albumId={album.id} />
        </div>
      </div>
    ) : (
      <div className="metadata">
        <div className="name">{name}</div>
        <div className="artist">{artist}</div>
        <div>
          <Year year={year} />
          <Description description={description} />
        </div>
        <div className="play-explore">
          <PlayButton piece={album} type="record" style="floating" />
          <ExploreAlbum albumId={album.id} />
        </div>
      </div>
    );

    return (
      <div className="record-of-the-day">
        <h2>{isMobile ? 'FROM ' : ''}OUR LIBRARY</h2>
        {!isMobile && <div className="divider"></div>}
        <h2>RECORD OF THE DAY</h2>
        <div className="record-content">
          <div className="cover">
            <img alt={name} src={cover.url} />
          </div>
          {metadata}
        </div>
      </div>
    );
  }
}
