import { getLibraryAlbums, getLibraryRecords } from './library';

/**
 * @template T CacheType
 */
export class MetadataCache {
  /**
   *
   * @param {() => Promise<T>} loadCache
   */
  constructor(loadCache) {
    this.__internal_load = async () => {
      this.cache = await loadCache();
      this.__internal_load = () => Promise.resolve(this.cache);
      return this.cache;
    };
    this.cache = null;
  }

  /**
   *
   * @returns {Promise<T>}
   */
  async getCache() {
    return this.__internal_load();
  }
}

export const AlbumsCache = new MetadataCache(getLibraryAlbums);
export const RecordsCache = new MetadataCache(getLibraryRecords);
