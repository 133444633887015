import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Flipped } from 'react-flip-toolkit';
import { useEhState } from '@foursk/eh-react-hooks';

import { play, sPlayer, pause } from '../../logic/player';
import { sLibraryRegion, useIsPlaying } from '../../logic/library';

import { Cover } from '../../components/CoverImage';
import { getRelatedContent } from '../../logic/content';
import { PlayTitle } from '../../components/interactive-content/PlayTitle';
import { ExploreButton } from '../../components/interactive-content/ExploreButton';
import { useHistory } from 'react-router-dom';

function Description({ description }) {
  return <div className="description">{description}</div>;
}

function Record({ record, idx, playerState }) {
  const { piece, playing } = playerState;
  const isActive = useIsPlaying(playing, piece, record);

  const onClick = () => (isActive ? pause() : play(record, 'record'));
  return (
    <div className={`record${isActive ? ' active' : ''}`} key={idx}>
      <div onClick={onClick}>{idx + 1}.</div>
      <div onClick={onClick}>{record.name}</div>
    </div>
  );
}

function Records({ records }) {
  const playerState = useEhState(sPlayer);

  return (
    <div className="records">
      {records.map((record, idx) => (
        <Record key={idx} record={record} idx={idx} playerState={playerState} />
      ))}
    </div>
  );
}

function AlbumRelatedContent({ album }) {
  const [relatedContent, setRelatedContent] = useState([]);
  const { push } = useHistory();

  useEffect(() => {
    if (album.content_tag)
      getRelatedContent(album.content_tag.id, {}, 3).then(setRelatedContent);
  }, []);
  if (!relatedContent || relatedContent.length === 0) return null;
  return (
    <div className="album-related-content-container">
      <span>Related</span>
      <span className="separator"></span>
      {relatedContent.map((rc, idx) => (
        <div
          key={idx}
          className="album-related-content"
          onClick={() => push(`/${rc.contentType}/${rc.id}`)}
        >
          <img src={rc.cover?.url} />
          <span>{rc.name}</span>
        </div>
      ))}
    </div>
  );
}

function StaticContainer_impl({ album }) {
  return [
    <PlayTitle piece={album} name={album.name} artist={album.artist} key={1} />,
    <div className="content-container" key={2}>
      <Records records={album.records} />
      <Description description={album.description} />
    </div>,
    <AlbumRelatedContent album={album} key={3} />,
  ];
}

function StaticContainer({ album }) {
  return (
    <div className="static-album-data">
      <StaticContainer_impl album={album} />
    </div>
  );
}

const isRegionSeparator = (album) => album.id.startsWith('region');

function RegionSeparator({ region }) {
  return (
    <div className="region-sep">
      <div></div>
      <span>{region}</span>
      <div></div>
    </div>
  );
}

export function Album({
  album,
  scrollContainerSelector,
  disableRegionBarIntegration,
  // setListMode,
  listMode,
  scrollOnExplore,
  renderIdx,
  setLastIntersected,
  displayAlbum,
  coversInRow,
}) {
  // console.log('album', album);
  const [isExpanded, setIsExpanded] = useState(false);
  const albumRef = useRef();

  const disableIntegration = useMemo(
    () => disableRegionBarIntegration || isRegionSeparator(album),
    [disableRegionBarIntegration, album]
  );

  const listModeRef = useRef(listMode);

  useEffect(() => {
    listModeRef.current = listMode;
  }, [listMode]);

  // useVisibleAlbumRegions(album, albumRef.current, disableIntegration);

  useEffect(() => {
    if (disableIntegration) return;

    album.element = albumRef.current;
    if (album.elementCallback) {
      album.elementCallback();
      delete album.elementCallback;
    }
    album.intersections = 0;
    /** @type {IntersectionObserverCallback} */
    const callbackList = (e) => {
      if (listModeRef.current === false) return;
      if (e[0] && e[0].isIntersecting === false) return;
      setLastIntersected({ renderIdx, element: album.element });

      console.log('LIST INTSCT', {
        region: album.geo_tag.value,
        intsc: album.intersections,
        e,
      });
      album.intersections++;
      // if (album.intersections > 1)
      sLibraryRegion.fire({ activeRegion: album.geo_tag.value });
    };
    // const callbackCover = () => {
    //   if (listMode) return;
    //   album.intersections++;
    //   // if (album.intersections > 1)
    //   sLibraryRegion.fire({ activeRegion: album.geo_tag.value });
    // };

    const observers = [
      [0.8, callbackList],
      // [1, callbackCover],
    ].map(([threshold, callback]) => {
      const observer = new IntersectionObserver(callback, { threshold });
      observer.observe(albumRef.current);
      return observer;
    });
    return () => {
      // alert(`album ${album.name} dying`);
      delete album.element;
      observers.forEach((o) => o.disconnect());
    };
  }, [disableIntegration]);

  useEffect(() => {
    setIsExpanded(false);
  }, [album, setIsExpanded]);

  if (isRegionSeparator(album))
    return <RegionSeparator region={album.region} />;

  return (
    <Flipped flipId={`album-${album.id}`} stagger={!listMode} translate>
      <div
        ref={albumRef}
        className={`album${isExpanded ? ' expanded' : ''}${
          (album.regionIdx + 1) % coversInRow === 0 ? '' : ' covers-margin'
        }`}
        onClick={() => {
          if (listMode || disableRegionBarIntegration) return;

          // eAnimationDone.registerOnce(() => {
          //   // albumRef.current.scrollIntoView(/*{ behavior: 'smooth' }*/);
          //   console.log('DISPLAYING ALBUM AFTER ANIMATION');
          //   displayAlbum(album.id);
          // });

          // setListMode(true);
          displayAlbum(album);
        }}
      >
        <div className="interactive-container">
          <Cover
            albumId={album.id}
            cover={album.cover}
            scrollContainerSelector={scrollContainerSelector}
          />
          <StaticContainer album={album} listMode={listMode} />
          <div className="spacer"></div>
        </div>
        <ExploreButton
          moreText="EXPLORE ALBUM"
          lessText="EXPLORE LESS"
          onClick={() => {
            setIsExpanded(!isExpanded);
            if (scrollOnExplore)
              albumRef.current.scrollIntoView({ behavior: 'auto' });
          }}
          isExpanded={isExpanded}
        />
      </div>
    </Flipped>
  );
}
