import React from 'react';

export function Hamburger({ ...props }) {
  return (
    <svg
      className="hamburger"
      width="43"
      height="21"
      viewBox="0 0 43 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <line
        y1="2.25"
        x2="43"
        y2="2.25"
        stroke="#9E3421"
        strokeWidth="3.5"
        strokeLinejoin="bevel"
      />
      <line
        y1="10.75"
        x2="43"
        y2="10.75"
        stroke="#9E3421"
        strokeWidth="3.5"
        strokeLinejoin="round"
      />
      <line
        y1="19.25"
        x2="43"
        y2="19.25"
        stroke="#9E3421"
        strokeWidth="3.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}
