import React, { useEffect, useState } from 'react';
import { RegionBar } from './RegionBar';
import { Albums } from './Albums';
import { useEhState } from '@foursk/eh-react-hooks';
import { sMobile } from '../../logic/responsive';
import ReactGA from 'react-ga';

export function Library() {
  const [listModeState, setListMode] = useState(true);
  const { portrait } = useEhState(sMobile);
  const listMode = listModeState || portrait;

  useEffect(() => {
    const gapath = window.location.pathname;
    console.log('pv ', gapath);
    ReactGA.pageview(gapath);
  }, []);

  return (
    <div
      className={`library ${listMode ? 'list' : 'covers'}`}
      style={{ '--scrollbar-thumb': 'transparent' }}
    >
      <Albums listMode={listMode} setListMode={setListMode} />
      <RegionBar />
    </div>
  );
}
