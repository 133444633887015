import {
  Q_SETS,
  Q_PODCASTS,
  Q_SETS_ID,
  Q_PODCASTS_ID,
  Q_PODCAST,
  Q_SET,
} from './journeys';
import { jgql } from './graphql';
import { isForHotelGuestsClause } from './utils';
import { Q_CONTENT } from './content';

const ARTICLE_FIELDS_NO_TAGS = `id
name
author
duration_seconds
cover {
  url
}
excerpt`;
const ARTICLE_FIELDS = `${ARTICLE_FIELDS_NO_TAGS}
content_tags{
  id
  value
}`;

export const Q_ARTICLES = (is_for_hotel_guests = null) =>
  Q_CONTENT('articles', ARTICLE_FIELDS, { is_for_hotel_guests });

export const Q_ARTICLES_ID = (isForHotelGuests = null) =>
  jgql(`
        articles ${isForHotelGuestsClause(isForHotelGuests)}{
          id
        }
      `);

export const Q_ARTICLE = (id) =>
  jgql(`
      article (id:${id}){
        ${ARTICLE_FIELDS}
        body
      }
    `);

export const Q_HOMEPAGE_STORIES = () =>
  jgql(`
  {
    homepageStory{
      article_top {
        ${ARTICLE_FIELDS_NO_TAGS}
      }
      article_bottom {
        ${ARTICLE_FIELDS_NO_TAGS}
      }
    }
  }
  `);

export const Q_STORIES = (isForHotelGuests = null) =>
  jgql(...[Q_ARTICLES, Q_SETS, Q_PODCASTS].map((q) => q(isForHotelGuests)));

export const Q_STORIES_ID = (isForHotelGuests = null) =>
  jgql(
    ...[Q_ARTICLES_ID, Q_SETS_ID, Q_PODCASTS_ID].map((q) => q(isForHotelGuests))
  );

export const Q_STORY = (contentType, id) => {
  let qId;
  switch (contentType) {
  case 'article':
    qId = Q_ARTICLE;
    break;
  case 'podcast':
    qId = Q_PODCAST;
    break;
  case 'set':
    qId = Q_SET;
    break;
  }
  return qId(id);
};
