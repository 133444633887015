import React from 'react';
import LazyLoad from 'react-lazyload';
import { LAZY_LOAD_DEBOUNCE } from '../logic/consts';

function Image({ cover, albumId }) {
  return (
    <img className="cover" alt={'Cover'} src={cover.url} id={`cover-${albumId}`} />
  );
}

export function Cover({ cover, albumId, scrollContainerSelector }) {

  return (
    <div className="cover-wrapper">
      {scrollContainerSelector ? (
        <LazyLoad
          once
          classNamePrefix="album-cover-lazy "
          // height={305}
          // placeholder={<Loader />}
          overflow
          scrollContainer={scrollContainerSelector}
          debounce={ LAZY_LOAD_DEBOUNCE }
        >
          <Image cover={cover} albumId={albumId} />
        </LazyLoad>
      ) : (
        <Image cover={cover} albumId={albumId} />
      )}
    </div>
  );
}
