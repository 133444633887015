import React from 'react';
export function OpenPlayerDesktop({...props}) {
  return (
    <svg width="31" height="21" viewBox="0 0 31 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1 20L14.9204 2.03586C15.3245 1.51436 16.114 1.52022 16.5103 2.04765L30 20" stroke="#DFD0C2" strokeWidth="2.5" />
    </svg>
  );
}

export function OpenPlayerMobile({...props}) {
  return (
    <svg width="23" height="14" viewBox="0 0 23 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1 13L11.6615 2L22 13" stroke="#DFD0C2" strokeWidth="2" />
    </svg>
  );
}