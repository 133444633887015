/**
 *
 * @param {any[]} array
 */
export function shuffle(array) {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export const isForHotelGuestsClause = (isForHotelGuests) =>
  isForHotelGuests === true ? '(where:{is_for_hotel_guests:true}) ' : '';

/**
 * Pad Zero to to number n where n > 0
 * @param {number} n
 */
export const padZero = (n) => {
  if (n < 10) {
    return `0${n}`;
  } else return n;
};

export const timeStr = (seconds, padMinutes = true) => {
  const minutes = Math.floor(seconds / 60);
  const secondsRest = Math.floor(seconds - minutes * 60);
  return `${padMinutes ? padZero(minutes) : minutes}:${padZero(secondsRest)}`;
};

export const dateStr = (date = new Date()) =>
  `${padZero(date.getDate())}.${padZero(
    date.getMonth() + 1
  )}.${date.getFullYear()}`;

export const datePlusMonthStr = (date = new Date()) => {
  date.setMonth(date.getMonth() + 1);
  return dateStr(date);
};

/**
 *
 * @param {string} datetime
 */
export const getHourString = (datetime) =>
  datetime.toTimeString().substring(0, 5);

export const durationStringForStory = (durationSeconds) => {
  const minutes = Math.ceil(durationSeconds / 60);
  if (minutes >= 100) {
    const hours = Math.floor(minutes / 60);
    const minutesRest = padZero(minutes % 60);
    return `${hours}:${minutesRest} H`;
  } else {
    return `${minutes} MIN`;
  }
};

// const TZDelta = new Date().getTimezoneOffset() * 60 * 1000;

export const addGetDateObj = (event) => {
  if (!event.dateObj) {
    const datetimeNoTz = new Date(event.datetime);
    event.dateObj = datetimeNoTz;
  }
  return event.dateObj;
};

export function chunk(list, chunkSize = 3) {
  if (!list.length) {
    return [];
  }

  var i,
    j,
    t,
    chunks = [];
  for (i = 0, j = list.length; i < j; i += chunkSize) {
    t = list.slice(i, i + chunkSize);
    chunks.push(t);
  }

  return chunks;
}

export const arrayNameToContentType = (arrayName) =>
  arrayName.substring(0, arrayName.length - 1);

export const objectify = (array, objectifier) =>
  Object.assign({}, ...array.map(objectifier));

export const randomSort = () => Math.random() - 0.5;

export const enrich = (target, source) => {
  // Object.keys(source).forEach((key) => {
  //   if (!target[key]) target[key] = source[key];
  // });

  // return target;
  return Object.assign({}, target, source);
};
