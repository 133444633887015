import { query } from '../graphql';
import {
  Q_RECORD_IDS,
  Q_RECORD,
  Q_RECORD_OF_THE_DAY_ID,
  Q_ALBUM_BY_ID,
} from '../library';
import { buildQueue } from './queue';
import { sPlayer, sPlayerProgress } from './state';
import { Q_PIECE } from './common';
import { randomSort, enrich } from '../utils';

const playsForPausing = {};
let playForPausingIdx = 0;

/**
 *
 * @param {{url: string, name: string, cover: string}} piece
 * @param {AudioType} type
 */
export async function play(piece, type, dontBuildQueue = false) {
  // console.log('play', piece);
  if (piece.records) {
    if (piece.records.length === 0) {
      console.error('Empty album');
      return;
    }

    const statePiece = sPlayer.state.piece;
    if (statePiece.album && statePiece.album.id === piece.id) {
      const existingPiece = piece.records.find((r) => r.id === statePiece.id);
      if (existingPiece) return play(existingPiece, 'record', dontBuildQueue);
    }

    return play(piece.records[0], 'record', dontBuildQueue);
  }

  if (
    !piece.audio_file ||
    piece.audio_file.url === '' ||
    (type === 'record' && !piece.album)
  ) {
    if (piece.id) return await getAndPlay(piece.id, type, piece);
    else return await playRecordOfTheDay();
  } else {
    const {
      audio: previousAudio,
      piece: previousPiece,
      volume,
    } = sPlayer.state;

    if (!piece.contentType) piece.contentType = type;

    if (previousPiece === piece && previousAudio.paused) {
      await previousAudio.play();
      return sPlayer.fire({ playing: true });
    }

    // if (previousAudio && false === previousAudio.paused) {
    //   previousAudio.pause();
    //   previousAudio.src = '';
    // }

    Object.keys(playsForPausing).forEach((i) => {
      const a = playsForPausing[i];
      if (a.paused === false) {
        console.log('Pausing');
        a.pause();
        a.src = '';
        a.ontimeupdate = null;
        a.ondurationchange = null;
        delete playsForPausing[i];
      }
    });

    const audio = new Audio(piece.audio_file.url);
    audio.preload = false;
    audio.volume = volume;
    audio.ondurationchange = () => {
      if (sPlayer.state.audio === audio) {
        sPlayerProgress.fire({ duration: audio.duration });
      }
    };

    audio.ontimeupdate = () => {
      if (sPlayer.state.audio === audio) {
        const percentageProgress =
          audio.duration && false === Number.isNaN(audio.duration)
            ? (audio.currentTime * 100) / audio.duration
            : 0;

        sPlayerProgress.fire({
          progress: audio.currentTime,
          percentageProgress,
        });

        if (
          audio.currentTime === audio.duration &&
          percentageProgress === 100
        ) {
          setTimeout(() => {
            if (sPlayer.state.audio === audio) playNext();
          }, 1000);
        }
      }
    };

    try {
      await audio.play();
      playsForPausing[playForPausingIdx++] = audio;
      await sPlayer.fire({
        playing: true,
        piece,
        type,
        audio,
        progress: 0,
        duration: NaN,
      });

      if (audio.duration > 0) {
        sPlayerProgress.fire({ duration: audio.duration });
      }

      if (false === dontBuildQueue) return buildQueue(piece, type, piece.id);
    } catch (playError) {
      console.error('Auto play was prevented', playError);
    }

    // const { tag } = queriesForAudioType[type];
    // const rebuildQueue =
    //   queue.length === 0 || previousType !== type || !previousType;
    // const previousPieceHasSameTagId =
    //   tagType === tag && hasTags(piece, tagType, tagId);

    // /**
    //  * Conditions for rebuilding queue
    //  * 1. new piece type
    //  * 2. new tag id
    //  * 3. queue is empty
    //  */
    // if (false === dontBuildQueue) {
    //   //if (rebuildQueue || false === previousPieceHasSameTagId)
    //   return buildQueue(piece, type, piece.id);
    //   //else return reshuffleQueue(piece);
    // }
  }
}

export async function pause() {
  sPlayer.fire({ playing: false });
  sPlayer.state.audio.pause();
}

export async function setVolume(volume) {
  sPlayer.state.audio.volume = volume;
  await sPlayer.fire({ volume });
}

/**
 * @returns {Promise<number>}
 */
export async function getRandomRecordId() {
  const { data } = await query(Q_RECORD_IDS);
  const randomIdx = Math.floor(data.records.length * Math.random());
  const { id } = data.records[randomIdx];
  return id;
}

export async function getRandomRecord() {
  const id = await getRandomRecordId();
  const {
    data: { record },
  } = await query(Q_RECORD(id));
  return record;
}

export async function playRecordOfTheDay() {
  const { data } = await query(Q_RECORD_OF_THE_DAY_ID);
  const { id: albumId } = data.recordOfTheDay.album;
  const {
    data: { records },
  } = await query(Q_ALBUM_BY_ID(albumId));
  if (records && records.length > 0) {
    return getAndPlay(records[0].id, 'record');
  }
}

/**
 *
 * @param {number} id
 * @param {AudioType} type
 */
export async function getAndPlay(id, type, existingPiece) {
  const { data } = await query(Q_PIECE(type, id));
  const piece = existingPiece ? enrich(existingPiece, data[type]) : data[type];
  if (piece.audio_file) await play(piece, type);
  else console.warn('No audio');
}

export async function playRandomRecord() {
  const id = await getRandomRecordId();
  await getAndPlay(id, 'record');
}

export async function playNext() {
  await sPlayerProgress.fire({ loadingNext: true });
  try {
    const { queue, cursor, type } = sPlayer.state;
    let newCursor = cursor;
    if (queue.length > 0) {
      if (newCursor >= queue.length - 1) {
        if (queue.length === 1) {
          return;
        }
        newCursor = 1;
        queue.reverse();
        const currentPiece = queue.shift();
        queue.sort(randomSort);
        queue.unshift(currentPiece);
      } else newCursor = cursor + 1;

      const nextPiece = queue[newCursor];
      await sPlayer.fire({ cursor: newCursor });
      await play(nextPiece, type, true);
    } else await playRecordOfTheDay();
  } catch (error) {
    console.error('Failed to play next:', error);
  }

  await sPlayerProgress.fire({ loadingNext: false });
}

export async function playPrevious() {
  const { queue, cursor, type } = sPlayer.state;
  if (queue.length > 0 && cursor > 0) {
    const previousCursor = cursor - 1;
    const previousPiece = queue[previousCursor];
    sPlayer.fire({ cursor: previousCursor });
    return play(previousPiece, type, true);
  }
}
