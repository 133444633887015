import React from 'react';
import { DayAgenda } from './DayAgenda';
import { RecordOfTheDay } from '../../../components/Record/RecordOfTheDay';
import { StoriesByContentTag } from './StoriesByContentTag';
import { useQuery } from '@apollo/react-hooks';
import { Q_EVENTS_TODAY } from '../../../logic/events';
import { apollo, gquery } from '../../../logic/graphql';

export function GuestContent() {
  const { data } = useQuery(gquery(Q_EVENTS_TODAY()), { client: apollo });
  // console.log('data', data);
  return (
    <div className="guest-content">
      {data && data.events && data.events.length > 0 && <DayAgenda />}
      <RecordOfTheDay />
      <StoriesByContentTag />
    </div>
  );
}
