import React, { useMemo } from 'react';
import Slider from 'rc-slider';
import { useEhState } from '@foursk/eh-react-hooks';
import { seek, sPlayerProgress } from '../../logic/player';
import { useState } from 'react';
import { isDesktop } from 'react-device-detect';

export function SeekableProgress() {
  const { duration, percentageProgress } = useEhState(sPlayerProgress);

  const [isDrag, setIsDrag] = useState(false);
  const [dragValue, setDragValue] = useState(false);

  const dragProps = useMemo(() => {
    const startDrag = () => {
      setIsDrag(true);
    };
    const stopDrag = () => {
      const dragFraction = dragValue / 1000;
      if (duration) {
        const newSeconds = dragFraction * duration;
        // overrideProgressQueue(dragFraction);
        seek(newSeconds < 1 ? 0 : newSeconds);
      } else {
        seek(dragFraction);
      }

      setIsDrag(false);
    };
    return isDesktop
      ? { onMouseDown: startDrag, onMouseUp: stopDrag }
      : { onTouchStart: startDrag, onTouchStop: stopDrag };
  }, [isDesktop, duration, setIsDrag, dragValue]);

  return (
    <div className="seekable-progress slider-container" {...dragProps}>
      <Slider
        className="volume-slider"
        onChange={(oneToThousand) => {
          setDragValue(oneToThousand);
        }}
        value={isDrag ? dragValue : percentageProgress * 10}
        min={0}
        max={1000}
      />
    </div>
  );
}
