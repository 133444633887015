import React from 'react';

export function LogoStar() {
  return (
    <svg
      className="logo-star"
      width="33"
      height="44"
      viewBox="0 0 33 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3187 18.3508L0 17.7828L11.4523 22.9145L1.14785 31.4673L11.4523 27.4716L10.311 43.4346L17.677 25.1016L28.6373 27.4716L21.7633 20.0614L32.927 14.2377L20.0448 16.0723V0.109253L14.3187 18.3508Z"
        fill="#1C1C1C"
      />
    </svg>
  );
}
