export const LAZY_LOAD_DEBOUNCE = 400;

export const PATHS = {
  LIBRARY: 'library',
  STORIES: 'stories',
  AGENDA: 'agenda',
  ARTICLE: 'article',
  SET: 'set',
  PODCAST: 'podcast',
  HOTEL_GUESTS: 'hotel-guests',
};

export const GAID = 'UA-209993838-1';
