import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useEhState } from '@foursk/eh-react-hooks';

import { sLoginOverlay } from 'logic/responsive';
import { OverlayEgg, TopStrip } from './common';
import { sLoggedIn } from '../../../logic/login';
import { PATHS } from '../../../logic/consts';

function OverlayInput({
  fieldName,
  displayName,
  creds,
  updateCreds,
  className,
}) {
  return (
    <div className={`overlay-input${className ? ` ${className}` : ''}`}>
      <input
        type="text"
        onChange={(e) => updateCreds({ ...creds, [fieldName]: e.target.value })}
        value={creds[fieldName] || ''}
      />
      <span>{displayName}</span>
    </div>
  );
}

function ShaharutRoundButton() {
  return <div className="shaharut-guest-round">SHAHARUT GUEST</div>;
}

const defaultCreds = { surname: '', room: '' };

export function GuestLoginOverlay() {
  const { on, error } = useEhState(sLoginOverlay);
  const { push, location } = useHistory();
  const [creds, setCreds] = useState(defaultCreds);
  // const updateCreds = update => setCreds(Object.assign({}, creds, update));

  return (
    <div className={`guest-overlay${on ? ' on' : ''}${error ? ' error' : ''}`}>
      <TopStrip
        onClose={() => {
          if (location.pathname.includes(PATHS.HOTEL_GUESTS)) {
            push('/');
          }
          sLoginOverlay.fire({ on: false, error: false });
        }}
        rightFlank={<ShaharutRoundButton />}
      />
      <OverlayEgg>
        <div className="title">SHAHARUT GUESTS</div>
        <div className="error-msg">
          Name or Room No. Incorrect <br></br> Please try again
        </div>
        <OverlayInput
          fieldName="surname"
          displayName="Surname"
          updateCreds={setCreds}
          creds={creds}
        />
        <OverlayInput
          fieldName="room"
          displayName="Room No."
          updateCreds={setCreds}
          creds={creds}
          className="room"
        />

        <div
          className="login-button"
          onClick={() => {
            if (creds.surname === '' || creds.surname === '') {
              sLoginOverlay.fire({ error: true });
              return;
            }
            push(`/${PATHS.HOTEL_GUESTS}`);
            sLoginOverlay.fire({ on: false, error: false });
            sLoggedIn.fire({ loggedIn: true });
            setCreds(defaultCreds);
          }}
        >
          LOG IN
        </div>
      </OverlayEgg>
    </div>
  );
}
