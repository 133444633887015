import React from 'react';
import { useEhState } from '@foursk/eh-react-hooks';
import { sMobile, sMenuOverlay } from 'logic/responsive';
import { Hamburger } from './Hamburger';
import { HeaderLink } from './HeaderLink';
import { PATHS } from '../../logic/consts';

export function HeaderLinks() {
  const { portrait } = useEhState(sMobile);

  if (portrait === true) {
    return <Hamburger onClick={() => sMenuOverlay.fire({ on: true })} />;
  } else {
    return (
      <div className="header-links">
        <HeaderLink to={PATHS.LIBRARY} />
        <HeaderLink to={PATHS.STORIES} />
        <HeaderLink to={PATHS.AGENDA} />
      </div>
    );
  }
}
