import { jgql, query } from './graphql';
import { arrayNameToContentType, isForHotelGuestsClause } from './utils';

const CONTENT_TYPES = ['articles', 'podcasts', 'sets'];
let mergedContentCache = null;

/**
 * @typedef {{is_for_hotel_guests?:boolean, id_ne?:string}} WhereOptions
 * @param {boolean} isForHotelGuests
 * @param {string} excludeId
 * @returns {WhereOptions}
 */
export const makeWhere = (isForHotelGuests, excludeId) => ({
  is_for_hotel_guests: isForHotelGuestsClause,
  id_ne: excludeId,
});

export const stringifyWhere = (where) =>
  Object.entries(where)
    .map(([key, val]) => `${key}:${JSON.stringify(val)}`)
    .join(', ');

/**
 *
 * @param {WhereOptions} whereOptions
 */
const whereToString = (whereOptions) => {
  if (!whereOptions) return '';
  Object.keys(whereOptions).forEach((key) => {
    if (whereOptions[key] === undefined || whereOptions[key] === null) {
      delete whereOptions[key];
    }
  });

  if (Object.keys(whereOptions).length === 0) return '';

  const retval = `(where:{${stringifyWhere(whereOptions)}})`;
  return retval;
};

/**
 *
 * @param {string} table
 * @param {string} fields
 * @param {WhereOptions} where
 * @param {number} limit
 */
export const Q_CONTENT = (table, fields, where /*, limit*/) => {
  const whereStr = whereToString(where);
  return jgql(`${table} ${whereStr} {
        ${fields}
    }
`);
};

export const RELATED_CONTENT_FIELDS = `name
description
excerpt
duration_seconds
cover{
  url
}
id
content_tags{
    id
    value
}`;

export const Q_RELATED_CONTENT = () =>
  jgql(
    ...CONTENT_TYPES.map((table) => Q_CONTENT(table, RELATED_CONTENT_FIELDS))
  );

/**
 * @typedef {{content_tags: {id:number}[]}} Content
 * @param {number} tag_id
 * @returns {(c:Content[])=>boolean}
 */
export const makeRelatedContentFilter =
  (tag_id, { contentType, id }) =>
    (c) => {
      const ret =
      (c.contentType !== contentType || c.id !== id) &&
      c.content_tags.some((t) => t.id === tag_id);
      return ret;
    };

const mergeValidKeysFilter = CONTENT_TYPES.includes.bind(CONTENT_TYPES);
const mergeContent = ({ data }) =>
  [].concat.apply(
    [],
    Object.keys(data)
      .filter(mergeValidKeysFilter)
      .map((contentType) =>
        data[contentType].map((i) => ({
          contentType: arrayNameToContentType(contentType),
          ...i,
        }))
      )
  );

/**
 *
 * @param {string} tag_id
 * @param {*} except
 * @returns ([]) => []
 */
const filterRelatedContent = (tag_id, except) => {
  const filter = makeRelatedContentFilter(tag_id, except);
  return (array) => array.filter(filter).slice(0, 3);
};

function getMergedContent() {
  if (mergedContentCache) return Promise.resolve(mergedContentCache);
  else {
    return query(Q_RELATED_CONTENT())
      .then(mergeContent)
      .then((merged) => {
        mergedContentCache = merged;
        return merged;
      });
  }
}

export function getRelatedContent(tagId, except, max = null) {
  const promise = getMergedContent().then(filterRelatedContent(tagId, except));
  if (max) return promise.then((arr) => arr.slice(0, max));
  else return promise;
}
