import React from 'react';

export function VolumeIcon() {
  return (
    <svg
      width="25"
      height="20"
      viewBox="0 0 25 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="volume-icon"
    >
      <rect
        x="0.5"
        y="6.5"
        width="6"
        height="7"
        rx="0.5"
        fill="var(--volume-icon-color)"
        stroke="var(--volume-icon-color)"
      />
      <path
        d="M15 15C17.7614 15 20 12.7614 20 10C20 7.23858 17.7614 5 15 5"
        stroke="var(--volume-icon-color)"
        strokeWidth="2"
      />
      <path
        d="M15 19C19.9706 19 24 14.9706 24 10C24 5.02944 19.9706 1 15 1"
        stroke="var(--volume-icon-color)"
        strokeWidth="2"
      />
      <path
        d="M1.75 10.433C1.41667 10.2406 1.41667 9.75944 1.75 9.56699L12.25 3.50481C12.5833 3.31236 13 3.55292 13 3.93782L13 16.0622C13 16.4471 12.5833 16.6876 12.25 16.4952L1.75 10.433Z"
        fill="var(--volume-icon-color)"
        stroke="var(--volume-icon-color)"
      />
    </svg>
  );
}
